import React, { useState } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TagLabel,
} from "@chakra-ui/react";
import Header from "../../Header";
import { AddIcon, DragHandleIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { BiSlideshow } from "react-icons/bi";
import UploadImageToS3WithReactS3 from "../../UploadImageToS3WithNativeSdk";
import Moment from "react-moment";
import "moment-timezone";
import { nanoid } from "nanoid";
import { Space, Table } from "antd";
import "antd/dist/antd.css";
import { RenderIf } from "../../../hooks/renderIF";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
const Presentaciones = () => {
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [urlPresentacion] = useState();
  const [nombrePresentacion, setNombrePresentacion] = useState("");
  const { Column } = Table;
  async function fetchPresentaciones() {
    // //console.log("refetch")
    // const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}?page-size=20`
    // const ventasData = await axios(urlC, {
    //     method: 'GET',
    //     headers: {
    //         'accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'X-Cassandra-Token': tokenCasandra
    //     }
    // })
    // console.log(ventasData.response)
    // const presentaciones =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
    // return presentaciones

    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/presentaciones`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log(ventasData.response);
    const presentaciones = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return presentaciones;
  }
  const crearPresentacion = async (data) => {
    const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-Cassandra-Token": tokenCasandra,
      },
      body: JSON.stringify({
        nombrePresentacion: nombrePresentacion,
        fechaCreacion: Date.now(),
        status: "Activo",
        url: urlPresentacion,
        provider: "google",
      }),
    });

    if (response.status === 500) {
      console.log("Segundo Intento");
      const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}`;
      await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-Cassandra-Token": tokenCasandra,
        },
        body: JSON.stringify({
          nombrePresentacion: nombrePresentacion,
          fechaCreacion: Date.now(),
          status: "Activo",
          url: urlPresentacion,
          provider: "google",
        }),
      });
      onClose();
    }

    onClose();
    return response;
  };
  const duplicarPresentacion = async (data) => {
    const urlC =
      "https://servicesemail-production-899b13b908d7.herokuapp.com/api/presentaciones";
    const body = {
      nombrePresentacion: data.nombrePresentacion + "_" + nanoid(),
      fechaCreacion: Date.now(),
      status: data.status,
      url: data.url,
      provider: data.provider,
    };
    await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  };
  const eliminarPresentacion = async (id) => {
    try {
      console.log(id);
      const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/eliminarPresentaciones/${id}`;
      const response = await fetch(urlC, {
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 204) {
        console.log("Entra");
        refetch();
      } // Espera a que se complete la eliminación antes de llamar a refetch
    } catch (error) {
      console.error(error);
    }
  };
  async function actualizar() {
    // const urlC = `${urlCassandra}collections/presentaciones${user.id.toString()}?page-size=20`
    // const ventasData = await axios(urlC, {
    //     method: 'GET',
    //     headers: {
    //         'accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'X-Cassandra-Token': tokenCasandra
    //     }
    // })
    // console.log(ventasData.response)
    // const presentaciones =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
    // return presentaciones

    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/presentaciones`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    //console.log(ventasData.response)
    const presentaciones = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return presentaciones;
  }
  const mutation_duplicar = useMutation(duplicarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_delete = useMutation(eliminarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  useMutation(crearPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { isLoading, refetch } = useQuery(
    "PRESENTACIONES",
    fetchPresentaciones,
    {
      onSuccess: (data) => {
        setDatos(data);
      },
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  const handleDelete = async (id) => {
    mutation_delete.mutate(id);
  };
  const handlerNombrePresentacion = (e) => {
    setNombrePresentacion(e);
  };

  const duplicar = (
    nombrePresentacion,
    status,
    url,
    fechaCreacion,
    provider,
  ) => {
    console.log("Duplicar");
    mutation_duplicar.mutate({
      nombrePresentacion,
      status,
      url,
      fechaCreacion,
      provider,
    });
  };
  console.log(user);
  return (
    <>
      <Modal
        size={"xl"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        zIndex={"9999"}
      >
        <div>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"lg"}
              fontWeight={"bold"}
              fontFamily={"system-ui, sans-serif"}
            >
              Subir nueva presentación
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {/* <Box p={'4'} borderWidth={'1px'} rounded={'lg'} bg={'gray.50'} >
                        <Box my={1} fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} color={'gray.500'} >Importa una presentación desde URL de <strong>Google Slides</strong></Box>
                        <Input onChange={(e) => handlerNombrePresentacion(e.target.value) } my={2} bg={'white'} placeholder='Nombre de tú presentación' fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} color={'gray.500'}  />
                        <Input onChange={(e) => handlerUrlPresentacion(e.target.value) } bg={'white'} fontSize={'11pt'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} placeholder='Pega aquí la URL de tu presentación de Google Slides'></Input>
                        <Center my={3}>
                            {nombrePresentacion && urlPresentacion ? 
                            <Button onClick={handleUpload} fontSize={'sm'} fontWeight={'medium'}  fontFamily={'system-ui, sans-serif'} bg={'orange'} color={'gray.700'} variant={'outline'} >Guardar</Button>
                            :
                            <Button disabled fontSize={'sm'} fontWeight={'medium'} color={'gray.800'} fontFamily={'system-ui, sans-serif'} bg={'gray'}  variant={'outline'}>Guardar</Button>
                        }
                        
                        </Center>
                        </Box> */}
              {/* <Box textAlign={'center'} my={4}>O</Box> */}
              <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                <Box
                  my={1}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"gray.500"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  Escribe un nombre para tu presentación
                </Box>
                <Input
                  bg={"white"}
                  onChange={(e) => handlerNombrePresentacion(e.target.value)}
                  type={"text"}
                  placeholder={"Presentación de Prueba"}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.800"}
                  fontFamily={"system-ui, sans-serif"}
                />
                <RenderIf isTrue={nombrePresentacion !== ""}>
                  <UploadImageToS3WithReactS3
                    nombre={nombrePresentacion}
                    cerrar={onClose}
                    query={actualizar}
                  />
                </RenderIf>
              </Box>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </div>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type === 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "21px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "6", base: "5" }}
                                  >
                                    Biblioteca de Presentaciones
                                  </Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"system-ui, sans-serif"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí podras subir y ver las presentaciones
                                    de tu compañia
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"gray.200"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  <Tooltip
                                    label="Subir nueva presentación"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Button
                                      colorScheme={"orange"}
                                      onClick={onOpen}
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      {datos ? (
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box
                              borderWidth={1}
                              borderRadius={"12px"}
                              zIndex={1}
                              py={3}
                            >
                              <Table
                                dataSource={datos}
                                bordered
                                pagination={{
                                  pageSize: 5,
                                }}
                              >
                                <Column
                                  title="Nombre de la presentación"
                                  dataIndex="nombrePresentacion"
                                  key="nombrePresentacion"
                                  render={(text, record, index) => (
                                    <Box>
                                      <RenderIf
                                        isTrue={record.provider === "s3"}
                                      >
                                        <Link
                                          href={`preview?url=https://view.officeapps.live.com/op/embed.aspx?src=${record.url}&action=edit&wdStartOn=2`}
                                        >
                                          <Flex>
                                            <Box
                                              fontSize={"21px"}
                                              fontWeight={"medium"}
                                              color={"gray.700"}
                                              mr={"3"}
                                            >
                                              <BiSlideshow />
                                            </Box>
                                            {record.nombrePresentacion}
                                          </Flex>
                                        </Link>
                                      </RenderIf>
                                      <RenderIf
                                        isTrue={record.provider === "google"}
                                      >
                                        <Link
                                          href={record.url}
                                          target={"_blank"}
                                        >
                                          <Flex>
                                            <Box
                                              fontSize={"21px"}
                                              fontWeight={"medium"}
                                              color={"gray.700"}
                                              mr={"3"}
                                            >
                                              <BiSlideshow />
                                            </Box>
                                            {record.nombrePresentacion}
                                          </Flex>
                                        </Link>
                                      </RenderIf>
                                    </Box>
                                  )}
                                />
                                <Column
                                  title="Estatus"
                                  dataIndex="status"
                                  key="status"
                                  render={(status) => (
                                    <Tag bg={"#f1ddbf"}>
                                      <TagLabel color={"#525e75"}>
                                        {status}
                                      </TagLabel>
                                    </Tag>
                                  )}
                                />
                                <Column
                                  title="Fecha de creación del acceso"
                                  dataIndex="fechaCreacion"
                                  key="fechaCreacion"
                                  render={(fechaEntrada) => (
                                    <Moment
                                      tz="America/Mexico_City"
                                      locale="ES"
                                      format="DD/MM/yyyy hh:mm:ss"
                                    >
                                      {fechaEntrada}
                                    </Moment>
                                  )}
                                />
                                <Column
                                  title="Acción"
                                  dataIndex="estatusAdmin"
                                  key="estatusAdmin"
                                  render={(text, record, index) => (
                                    <Space size="middle">
                                      <Menu>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={<DragHandleIcon />}
                                        >
                                          Acciones
                                        </MenuButton>
                                        <MenuList>
                                          <MenuItem
                                            onClick={() =>
                                              duplicar(
                                                record.nombrePresentacion,
                                                record.status,
                                                record.url,
                                                record.fechaCreacion,
                                                record.provider,
                                              )
                                            }
                                          >
                                            Duplicar
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              handleDelete(record.id)
                                            }
                                          >
                                            Eliminar
                                          </MenuItem>
                                        </MenuList>
                                      </Menu>
                                    </Space>
                                  )}
                                />
                              </Table>

                              {/* <Table variant='simple' size={'lg'} fontFamily={'system-ui, sans-serif'} p={10}>
                                                            <Thead>
                                                                <Tr>
                                                                    <Th fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} >Nombre de la presentación</Th>
                                                                    <Th fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} >Status</Th>
                                                                    <Th fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} >Fecha de Creación</Th>
                                                                    <Th fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} ></Th>
                                                                </Tr>
                                                            </Thead>
                                                            {datos
                                                            .sort((a, b) => b.fechaCreacion - a.fechaCreacion)
                                                            .map((p)=>{
                                                                const url = p.url
                                                                return(
                                                                <Tbody size={'sm'}>
                                                                    <Tr key={p.id}>
                                                                        <Td fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} color={'gray.700'} >
                                                                            {p.provider == "s3" ? 
                                                                                <Link href={`preview?url=https://view.officeapps.live.com/op/embed.aspx?src=${p.url}&action=edit&wdStartOn=2`}>
                                                                                    <Flex>
                                                                                        <Box fontSize={'21px'} fontWeight={'medium'} color={'gray.700'} mr={'3'}>
                                                                                            <BiSlideshow />
                                                                                        </Box>
                                                                                        {p.nombrePresentacion}
                                                                                    </Flex>
                                                                                </Link>
                                                                                :
                                                                                <Link href={p.url} target={'_blank'}>
                                                                                <Flex>
                                                                                    <Box fontSize={'21px'} fontWeight={'medium'} color={'gray.700'} mr={'3'}>
                                                                                        <BiSlideshow />
                                                                                    </Box>
                                                                                    {p.nombrePresentacion}
                                                                                </Flex>
                                                                            </Link>
                                                                            }
                                                                        </Td>
                                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}><Tag colorScheme={'green'}>{p.status}</Tag></Td>
                                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>
                                                                        <Moment tz="America/Mexico_City" locale='ES' format='DD/MM/yyyy hh:mm:ss'>{p.fechaCreacion}</Moment>
                                                                        </Td>
                                                                        <Td fontSize={'15px'} color={'gray.600'} fontWeight={'normal'}>
                                                                                <Menu>
                                                                                    <MenuButton as={Button} rightIcon={<DragHandleIcon />}>
                                                                                       Acciones
                                                                                    </MenuButton>
                                                                                    <MenuList>
                                                                                        <MenuItem onClick={() => duplicar(p.nombrePresentacion,p.status,p.url,p.fechaCreacion, p.provider)}>Duplicar</MenuItem>
                                                                                        <MenuItem onClick={() => handleDelete(p.id)}>Eliminar</MenuItem>
                                                                                    </MenuList>
                                                                                </Menu>
                                                                        </Td>
                                                                        
                                                                    </Tr>
                                                                </Tbody>)
                                                            })}
                                                        </Table> */}
                            </Box>
                          </Center>
                        </Box>
                      ) : (
                        "Aún no cuentas con presentaciones"
                      )}
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Presentaciones;
