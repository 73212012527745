import React, { useEffect, useState, useRef } from "react";
import {
  Spinner,
  Input,
  Button,
  Text,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Select,
  Tag,
  TagLabel,
  Collapse,
  IconButton,
} from "@chakra-ui/react";
import Header from "../../Header";
import { AddIcon, EditIcon, DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { useParams } from "react-router-dom";
import { BsPinMapFill } from "react-icons/bs";
import { HiDocumentText, HiDotsHorizontal } from "react-icons/hi";
import { GoDotFill } from "react-icons/go";
import Moment from "react-moment";
import "moment-timezone";
import { nanoid } from "nanoid";
import * as AWS from "aws-sdk";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import { RenderIf } from "../../../hooks/renderIF";
import { GrFormDown, GrFormUp } from "react-icons/gr";
import { IoMdSend } from "react-icons/io";
import Blur from "react-css-blur";
import { useClipboard } from "../../../hooks/copy";
import { connect } from "react-redux";
import { get_link, get_crm } from "../../../actions";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import {
  get_proyectos,
  delete_proyecto,
  edit_proyecto,
  get_proyecto_by_id,
} from "../../../actions/proyectos";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDebouncedCallback } from "use-debounce";
import PhoneInput from "react-phone-input-2";
//import "react-phone-input-2/lib/bootstrap.css";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
}); //PRODUCCION
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const docClient = new AWS.DynamoDB.DocumentClient({ apiVersion: "2022-01-01" });
const _Detalles = (props) => {
  const MessageRef = useRef(null);
  let { id, nombreCliente } = useParams();
  const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const {
    isOpen: isProyectoOpen,
    onOpen: onOpenProyectoModal,
    onClose: onCloseProyectoModal,
  } = useDisclosure();
  const {
    isOpen: isProyectoOpenEdit,
    onOpen: onOpenProyectoModalEdit,
    onClose: onCloseProyectoModalEdit,
  } = useDisclosure();
  const {
    isOpen: isContactoModalOpen,
    onOpen: onOpenContactoModal,
    onClose: onCloseContactoModal,
  } = useDisclosure();
  const {
    isOpen: isContactoEditModalOpen,
    onOpen: onOpenContactoEditModal,
    onClose: onCloseContactoEditModal,
  } = useDisclosure();
  const {
    isOpen: isNotasModalOpen,
    onOpen: onOpenNotasModal,
    onClose: onCloseNotasModal,
  } = useDisclosure();
  const [datos, setDatos] = useState([]);
  const [urlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const { user } = useUser();
  const [nombre, setNombre] = useState("");
  const [categoria, setCategoria] = useState("");
  const [nombreContacto, setNombreContacto] = useState("");
  const [whatsapp, setWhatsApp] = useState("");
  const [contactoCorreo, setContactoCorreo] = useState("");
  const [contactoPuesto, setContactoPuesto] = useState("");
  const [notas, setNotas] = useState("");
  const [datosCategorias, setDatosCategorias] = useState([]);
  const [lineaID, setLineaID] = useState("");
  const [lineaNombre, setLineaNombre] = useState("");
  const [cargando, setCargando] = useState(false);
  const [tipo, setTipo] = useState("Privado");
  const [proyectos, setProyectos] = useState();
  const [contactosAdicionales, setContactosAdicionales] = useState();
  const [notasDatos, setNotasDatos] = useState();
  const [changeMessage, onChangeMessage] = useState("");
  const [contactoId, setContactoId] = useState();
  const [dominios, setDominios] = useState();
  const [fuente] = useState("Montserrat");
  const { isOpen: isColapse, onToggle } = useDisclosure({
    defaultIsOpen: false,
  });
  const [blurOn, setBlurOn] = useState(false);
  const clipboard = useClipboard({ timeout: 2000 });
  const [oportunidad, setOportunidad] = useState("");
  const { link, crm, proyecto } = props || {};
  const [phone, setPhone] = useState("52");
  const [oportunidades, setOportunidades] = useState([]);
  const [errores, setErrores] = useState();
  const [pickerItems, setPickerItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    props.get_crm(urlCassandra, tokenCasandra);
  }, []);
  const scrollToBottom = () => {
    MessageRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const debouncedUpdates = useDebouncedCallback(async (e) => {
    refetchProyectos();
  }, 1000);
  const debouncedUpdatesClose = useDebouncedCallback(async (e) => {
    onCloseProyectoModalEdit();
  }, 1000);
  const debouncedEdit = useDebouncedCallback(async (e) => {
    setTimeout(() => {
      if (proyecto != undefined) {
        setTipo(proyecto[0].tipo);
        setNombre(proyecto[0].nombreProyecto);
        setLineaID(proyecto[0].lineaNegocio);
        setCategoria(proyecto[0].categoria);
        setOportunidad(proyecto[0].oportunidad);
      }
    }, 750);
    setTimeout(() => {
      onOpenProyectoModalEdit();
    }, 750);
  }, 1000);
  async function fetchProyectos() {
    try {
      var params = {
        TableName: "Proyectos",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "idProyecto",
        },
        ExpressionAttributeValues: {
          ":idProyecto": id,
        },
      };
      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          setProyectos(data.Items);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchContactos() {
    try {
      var params = {
        TableName: "Contactos_Adicionales",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "idProyecto",
        },
        ExpressionAttributeValues: {
          ":idProyecto": id,
        },
      };

      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          //console.log("Query succeeded.");
          //refetchContactos()
          setContactosAdicionales(data.Items);
        }
      });
    } catch (error) {
      return [];
    }
  }
  async function fetchNotas() {
    try {
      var params = {
        TableName: "Notas",
        FilterExpression: "#idProyecto = :idProyecto",
        ExpressionAttributeNames: {
          "#idProyecto": "idProyecto",
        },
        ExpressionAttributeValues: {
          ":idProyecto": id,
        },
      };

      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          //console.log("Query succeeded.");
          setNotasDatos(data.Items);
        }
      });
    } catch (error) {
      return [];
    }
  }
  async function fetchLineas() {
    const urlC = "https://servicesemail-production-899b13b908d7.herokuapp.com/api/lineas";
    const lineasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const lineas = await Object.keys(lineasData.data).map((item) => ({
      id: item,
      ...lineasData.data[item],
    }));
    return lineas;
  }
  async function fetchCategorias(idCat) {
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/categorias/${idCat}`;
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const categorias = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    setDatosCategorias(categorias);
    return categorias;
  }
  async function fetchClientes() {
    const clientes = fetch(urlStrapi + "/clientes/" + id).then((response) =>
      response.json(),
    );
    return clientes;
  }

  const getOpportunities = async () => {
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/opportunities?cardid=${id}`;
    //const urlC = `http://localhost:8080/api/opportunities?cardid=${id}`;
    const oportunidadList = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    setOportunidades(oportunidadList.data);
    // return oportunidadList;
  };

  useEffect(() => {
    setCliente(id);
    scrollToBottom();
    props.get_link();
    getOpportunities();
  }, []);
  const eliminarContacto = async (contactId) => {
    var params = {
      TableName: "Contactos_Adicionales",
      Key: {
        id: contactId,
      },
    };

    docClient.delete(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        refetchContactos();
      }
    });
  };
  const putData_proyectos = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };
    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        //refetch_c()
        fetchProyectos();
      }
    });
  };
  const crear = async (t) => {
    const Data = {
      id: nanoid().toString(),
      nombreProyecto: nombre,
      vendedor: user.id.toString(),
      vendedorName: user.username,
      suma: 1,
      estatus: "Activo",
      fechaCreacion: Date.now(),
      lineaNegocio: lineaID,
      lineaNegocioNombre: lineaNombre,
      categoria,
      tipo: tipo,
      oportunidad,
      token: t,
      idProyecto: id.toString(),
      slug: `${user.username}_${lineaNombre}_all`,
      slug_filter: `${user.id.toString()}_${lineaID}_all`,
      nombreEmpresa: nombreCliente,
      dominios: dominios,
    };
    await putData_proyectos("Proyectos", Data);
  };
  const putData_contactos = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };
    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        refetchContactos();
        //refetch_c()
      }
    });
  };
  const crearContactos = async (t) => {
    const Data = {
      id: nanoid(),
      nombre: nombreContacto,
      whatsapp: `+${whatsapp}`,
      correo: contactoCorreo,
      fechaCreacion: Date.now(),
      puesto: contactoPuesto,
      idProyecto: id,
      telOficina: `+${phone}`,
    };
    await putData_contactos("Contactos_Adicionales", Data);
  };
  const putData_notas = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };
    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        fetchNotas();
      }
    });
  };
  const crearNotas = async (t) => {
    const Data = {
      id: nanoid(),
      nota: notas,
      fechaCreacion: Date.now(),
      idProyecto: id,
    };
    await putData_notas("Notas", Data);
  };
  const editContactos = async (id) => {
    try {
      const params = {
        TableName: "Contactos_Adicionales",
        Key: {
          id: id,
        },
        UpdateExpression:
          "set #nombre = :nombre , #whatsapp = :whatsapp,#telOficina = :telOficina, #puesto = :puesto , #correo = :correo",
        ExpressionAttributeNames: {
          "#nombre": "nombre",
          "#whatsapp": "whatsapp",
          "#puesto": "puesto",
          "#correo": "correo",
          "#telOficina": "telOficina",
        },
        ExpressionAttributeValues: {
          ":nombre": nombreContacto,
          ":whatsapp": whatsapp,
          ":puesto": contactoPuesto,
          ":correo": contactoCorreo,
          ":telOficina": phone,
        },
      };

      docClient.update(params, function (err, data) {
        if (err) {
          console.log("Error", err);
        } else {
          refetchContactos();
          onCloseContactoEditModal();
        }
      });
    } catch (error) {}
  };
  const mutation_edit_contact = useMutation(editContactos, {
    onSuccess: () => {
      queryClient.invalidateQueries("CONTACTOS");
      setCargando(false);
      onOpenContactoEditModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const mutation = useMutation(crear, {
    onSuccess: () => {
      queryClient.invalidateQueries("PROYECTOS");
      setCargando(false);
      onCloseProyectoModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_b = useMutation(crearContactos, {
    onSuccess: () => {
      queryClient.invalidateQueries("CONTACTOS");
      setCargando(false);
      onCloseContactoModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_c = useMutation(crearNotas, {
    onSuccess: () => {
      queryClient.invalidateQueries("NOTAS");
      setCargando(false);
      onCloseNotasModal();
      setNotas("");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_d = useMutation(eliminarContacto, {
    onSuccess: () => {
      queryClient.invalidateQueries("CONTACTOS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  function handleonclick(t) {
    mutation.mutate(t);
  }
  function handleonclickContactos(t) {
    mutation_b.mutate(t);
  }
  function handleonclickNota(t) {
    mutation_c.mutate(t);
  }
  function deleteContact(id) {
    mutation_d.mutate(id);
  }

  function buscarCategorias(id, nombre) {
    setLineaID(id);
    setLineaNombre(nombre);
    fetchCategorias(id);
  }
  function buscarOportunidades(oppid, label, setFieldValue) {
    //console.log(oppid,name)
    setOportunidad(oppid);
    const res = oportunidades.filter((a) => a.OppID === oppid);
    setNombre(res[0].OppName);
    setFieldValue("nombre", res[0].OppName);
  }
  // const { isLoading } = useQuery("CLIENTES", fetchClientes, {
  //   onSuccess: (data) => {
  //     setDatos(data);
  //   },
  //   cacheTime: Infinity,
  // });
  const {
    query,
    refetch: refetchProyectos,
    isLoading,
  } = useQuery("PROYECTOS", fetchProyectos);
  const result = proyectos;
  const { refetch: refetchContactos } = useQuery("CONTACTOS", fetchContactos);
  const result_contactos = contactosAdicionales;
  const query_notas = useQuery("NOTAS", fetchNotas);
  const result_notas = notasDatos;
  const query_lineas = useQuery("LINEAS", fetchLineas);
  const result_lineas = query_lineas.data;
  const { data, refetch } = useQuery("CATEGORIAS", fetchCategorias, {
    refetchOnWindowFocus: false,
    enabled: false, // turned off by default, manual refetch is needed
    onSuccess: (data) => {
      setDatosCategorias(data);
    },
  });
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };
  const handlerNotas = (event) => {
    var today = new Date();
    const fecha = today.toLocaleDateString("es-MX");
    const str = event;
    const res = str.replace("/hoy", fecha);
    setNotas(res);
  };
  const BlurOn = (blurOn) => {
    setBlurOn(blurOn);
  };

  const handleonclickContactosEdit = (id) => {
    mutation_edit_contact.mutate(id);
  };
  async function fetchClienteById(id) {
    onOpenContactoEditModal();
    setContactoId(id);
    try {
      var params = {
        TableName: "Contactos_Adicionales",
        FilterExpression: "#id = :id",
        ExpressionAttributeNames: {
          "#id": "id",
        },
        ExpressionAttributeValues: {
          ":id": id,
        },
      };

      docClient.scan(params, function (err, data) {
        if (err) {
          console.error(
            "Unable to query. Error:",
            JSON.stringify(err, null, 2),
          );
        } else {
          //refetchContactos()
          //setContactosAdicionales(data.Items)

          setNombreContacto(data.Items[0].nombre);
          setWhatsApp(data.Items[0].whatsapp);
          setContactoCorreo(data.Items[0].correo);
          setContactoPuesto(data.Items[0].puesto);
        }
      });
    } catch (error) {
      return [];
    }
  }
  const eliminar = async (proyecto) => {
    props.delete_proyecto(proyecto);
    debouncedUpdates(user.id.toString());
  };
  const editar = async (proyecto) => {
    debouncedEdit(proyecto);
    props.get_proyecto_by_id(proyecto);
  };

  const update = async (proyecto) => {
    debouncedUpdates(user.id.toString());
    debouncedUpdatesClose();
  };

  function handlerSelectTipo(oppid, label, setFieldValue) {
    console.log(oppid);
    setTipo(oppid);
    setFieldValue("tipo", oppid);
  }
  // Expresión regular para validar correos electrónicos con dominios empresariales, excluyendo algunos dominios específicos

  function handlerEmailPublico(oppid, setFieldValue, setFieldError, errors) {
    console.log(oppid);
    const dominios = String(oppid)
      .split(",")
      .map((d) => d.trim());
    // Expresión regular para aceptar solo dominios empresariales o específicos
    const dominioRegex = /^[a-zA-Z0-9.-]+\.(com|mx|org|com\.mx|net|edu)$/i;

    for (let dominio of dominios) {
      if (!dominioRegex.test(dominio)) {
        // Agregar un mensaje de error más descriptivo
        setFieldError(
          "oppid",
          `El dominio "${dominio}" no es un dominio empresarial válido.`,
        );
        return false;
      }
    }
    dominiosValidos(oppid, setFieldValue);
    return true;
  }

  const dominiosValidos = (oppid, setFieldValue) => {
    const validar = validarCorreo(oppid);
    if (validar) {
      // Ejecutar algo si el correo es válido
      setDominios(oppid);
      setFieldValue("dominio", oppid);
      setErrores("");
      console.log("El correo electrónico es válido");
    } else {
      setErrores("El dominio no es válido, debe ser un dominio empresarial");
      console.log("El correo electrónico no es válido");
    }
  };

  //const emailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|hotmail\.com|yahoo\.com|msn\.com|Icloud\.com|prodigy\.com|telmex\.com)[a-zA-Z0-9.-]+\.(ms|com|mx|org|com.mx|net|edu)$/;
  const emailRegex = new RegExp(
    "^(?!.*(gmail\\.com|hotmail\\.com|yahoo\\.com|msn\\.com|icloud\\.com|prodigy\\.com|telmex\\.com))[a-zA-Z0-9.-]+\\.(ms|com|mx|org|com\\.mx|net|edu)$",
    "i",
  );
  // Función para validar un correo electrónico
  function validarCorreo(correo) {
    return emailRegex.test(correo);
  }

  function validarDominios(dominio) {
    return new Promise((resolve, reject) => {
      const DominioRegex = new RegExp(
        "^(?!.*(gmail\\.com|hotmail\\.com|yahoo\\.com|msn\\.com|icloud\\.com|prodigy\\.com|telmex\\.com))[a-zA-Z0-9.-]+\\.(ms|com|mx|org|com\\.mx|net|edu)$",
        "i",
      );

      // Aquí se realiza la operación asíncrona (en este caso, es síncrona)
      if (DominioRegex.test(dominio)) {
        resolve(true); // La promesa se resuelve si el dominio es válido
      } else {
        reject("El dominio no es válido"); // La promesa se rechaza si el dominio no es válido
      }
    });
  }

  const handleCreateItem = (item) => {
    console.log(item);
    validarDominios(item.value)
      .then((resultado) => {
        setErrores("");
        setPickerItems([...pickerItems, item]);
        setSelectedItems([...selectedItems, item]);
      })
      .catch((error) => {
        setErrores("El dominio no es válido, debe ser un dominio empresarial"); // Mostrará "El dominio no es válido" si el dominio es inválido
      });
  };

  const handleSelectedItemsChange = (selectedItems) => {
    if (selectedItems) {
      setSelectedItems(selectedItems);
    }
  };

  const handleBlurr = (event) => {
    const inputValue = event.target.value;
    const newDomains = inputValue
      .split(",")
      .map((domain) => domain.trim())
      .filter((domain) => domain !== "");

    const validDomains = newDomains.filter(
      (domain) => !selectedItems.includes(domain),
    );

    validDomains.forEach((domain) => handleCreateItem(domain));
  };
console.log({result})
  return (
    <>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpen}
        onClose={onCloseProyectoModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize={"15pt"}
            fontWeight={"bold"}
            fontFamily={fuente}
            textTransform={"uppercase"}
          >
            Nuevo Proyecto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                tipo: "Privado",
                nombre: "",
                lineaNombre: "",
                categoria: "",
                dominio: "",
                oportunidad: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.tipo) {
                  errors.tipo = "Este campo es requerido.";
                }
                if (!values.nombre) {
                  errors.nombre = "Este campo es requerido.";
                }
                //  if (!values.dominio) {
                //    errors.dominio = "Este campo es requerido.";
                //  }
                if (!values.lineaNombre) {
                  errors.lineaNombre = "Este campo es requerido.";
                }
                if (!values.categoria) {
                  errors.categoria = "Este campo es requerido.";
                }
                if (!values.oportunidad) {
                  errors.oportunidad = "Este campo es requerido.";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                //setTimeout(() => {
                //props.create_tecnico(values)

                setTipo(values.tipo);
                setDominios(selectedItems);
                setNombre(values.nombre);
                setCategoria(values.categoria);
                setOportunidad(values.oportunidad);

                handleonclick(datos.token);
                setSubmitting(false);
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldError,
                /* and other goodies */
              }) => (
                <Box mt={10}>
                  <form onSubmit={handleSubmit}>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Tipo de Proyecto
                    </Text>
                    <Field
                      as="select"
                      name="tipo"
                      onChange={(e) => {
                        handlerSelectTipo(
                          e.target.value,
                          e.target.selectedOptions[0].text,
                          setFieldValue,
                        );
                      }}
                      onBlur={handleBlur}
                      value={values.tipo}
                      fontSize={"11pt"}
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        borderWidth: 1,
                        width: "100%",
                      }}
                    >
                      <option value="Privado" name="Privado" selected>
                        Privado
                      </option>
                      <option value="Público" name="Público">
                        Público
                      </option>
                    </Field>
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="tipo" />
                    </Box>
                    {tipo === "Público" ? (
                      <>
                        <Text
                          fontSize={"sm"}
                          fontWeight={"medium"}
                          fontFamily={fuente}
                          my={2}
                        >
                          Agregar los dominios que podrán ver este contenido
                          (separados por comas)
                        </Text>
                        {/* <Input
                      type="text"
                      name="dominio"
                      onChange={(e) => {
                        // Llama a handleChange para que Formik maneje el cambio de valor.
                        handleChange(e);

                        // Procesa el valor del input para obtener los dominios como un array
                        const dominios = e.target.value.split(',').map(d => d.trim());

                        // Llama a tu función personalizada aquí, si es necesario, con el array de dominios
                        handlerEmailPublico(
                          dominios,
                          setFieldValue,
                          setFieldError,
                          errors
                        );
                      }}
                      onBlur={handleBlur}
                      value={values.dominio}
                      placeholder="empresa.com, empresa2.com"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="dominio" />
                      {errores}
                    </Box> */}
                        {/* <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Agregar los dominios que podrán ver este contenido
                    </Text>
                    <Input
                      type="text"
                      name="dominio"
                      onChange={(e) => {
                        // Llama a handleChange para que Formik maneje el cambio de valor.
                        handleChange(e);

                        // Llama a tu función personalizada aquí.
                        handlerEmailPublico(
                          e.target.value,
                          setFieldValue,
                          setFieldError,
                          errors
                        );
                      }}
                      onBlur={handleBlur}
                      value={values.dominio}
                      placeholder="empresa.com"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="dominio" />
                      {errores}
                    </Box> */}

                        <CUIAutoComplete
                          tagStyleProps={{ rounded: "full" }}
                          placeholder="empresa.com, empresa2.com"
                          onCreateItem={handleCreateItem}
                          items={pickerItems}
                          selectedItems={selectedItems}
                          onSelectedItemsChange={(changes) =>
                            handleSelectedItemsChange(changes.selectedItems)
                          }
                          onBlur={handleBlurr}
                          createIsOpen={false} // Esta es la propiedad que oculta el botón de creación
                        />
                        <Box color={"red.500"} fontSize={"10pt"}>
                          <ErrorMessage name="dominio" />
                          {errores}
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Lista de Oportunidades
                    </Text>

                    <>
                      {oportunidades ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Field
                            as="select"
                            name="oportunidad"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);
                              const selectedOption =
                                e.target.selectedOptions[0];
                              // Llama a tu función personalizada aquí.
                              buscarOportunidades(
                                // e.target.selectedOptions[0].text
                                selectedOption.value,
                                selectedOption.label,
                                setFieldValue,
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.oportunidad}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">
                              Seleccionar una oportunidad
                            </option>
                            {oportunidades.map((linea, index) => {
                              return (
                                <option
                                  key={index}
                                  value={linea.OppID}
                                  label={linea.OppID}
                                >
                                  {linea.OppID}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay lineas de negocios"
                      )}
                    </>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Nombre del proyecto
                    </Text>
                    <Input
                      type="text"
                      name="nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nombre}
                      placeholder="Escribe un nombre para tu proyecto"
                      fontSize={"11pt"}
                      bg={"white"}
                      style={style.fonts}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="nombre" />
                    </Box>

                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Linea de Negocios
                    </Text>

                    <>
                      {result_lineas ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Field
                            as="select"
                            name="lineaNombre"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);

                              // Llama a tu función personalizada aquí.
                              buscarCategorias(
                                e.target.value,
                                e.target.selectedOptions[0].text,
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.lineaNombre}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">
                              Seleccionar una Linea de Negocios
                            </option>
                            {result_lineas.map((linea, index) => {
                              return (
                                <option
                                  key={index}
                                  value={linea.id}
                                  name={linea.nombre}
                                >
                                  {linea.nombre}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay lineas de negocios"
                      )}
                    </>

                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="lineaNombre" />
                    </Box>

                    <>
                      {datosCategorias ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Text
                            fontSize={"sm"}
                            fontWeight={"medium"}
                            fontFamily={fuente}
                            my={2}
                          >
                            Selecciona una Categoría
                          </Text>
                          <Field
                            as="select"
                            name="categoria"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.categoria}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">Selecciona una Categoría</option>
                            {datosCategorias.map((categoria) => {
                              return (
                                <option value={categoria.categoria}>
                                  {categoria.categoria}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay categorias"
                      )}
                    </>

                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="categoria" />
                    </Box>
                    {/* <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      ID Oportunidad
                    </Text>
                    <Input
                      type="text"
                      name="oportunidad"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.oportunidad}
                      placeholder="Escribe el ID de la Oportunidad del CRM"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="oportunidad" />
                    </Box> */}

                    {/* <Center mt={5} >
                                            <Button type="submit" disabled={isSubmitting} bgGradient='linear(to-r, #003049,gray.700,gray.600)' color={'white'} w={'200px'} rounded={'25'}>
                                                Crear
                                            </Button>
                                        </Center> */}
                    <Flex justify={"end"} mt={5}>
                      <Button
                        mr={2}
                        type="submit"
                        disabled={isSubmitting}
                        isLoading={cargando}
                        bg={"orange.400"}
                        color={"white"}
                        fontFamily={fuente}
                      >
                        Crear
                      </Button>
                      <Button onClick={onCloseProyectoModal}>Cancelar</Button>
                    </Flex>
                  </form>
                </Box>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {/* actualizar proyecto*/}
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpenEdit}
        onClose={onCloseProyectoModalEdit}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize={"15pt"}
            fontWeight={"bold"}
            fontFamily={fuente}
            textTransform={"uppercase"}
          >
            Editar Proyecto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                tipo: tipo,
                nombre: nombre,
                lineaNombre: lineaID,
                categoria: categoria,
                oportunidad: oportunidad,
              }}
              validate={(values) => {
                const errors = {};

                if (!values.tipo) {
                  errors.tipo = "Este campo es requerido.";
                }
                if (!values.nombre) {
                  errors.nombre = "Este campo es requerido.";
                }
                if (!values.lineaNombre) {
                  errors.lineaNombre = "Este campo es requerido.";
                }
                if (!values.categoria) {
                  errors.categoria = "Este campo es requerido.";
                }
                if (!values.oportunidad) {
                  errors.oportunidad = "Este campo es requerido.";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTipo(values.tipo);
                setNombre(values.nombre);
                setCategoria(values.categoria);
                setOportunidad(values.oportunidad);
                props.edit_proyecto(
                  proyecto[0].id,
                  values.nombre,
                  values.oportunidad,
                  values.tipo,
                  lineaID,
                  lineaNombre,
                  values.categoria,
                  values.nombreCliente,
                );
                update(proyecto);
                setSubmitting(false);
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Box mt={10}>
                  <form onSubmit={handleSubmit}>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Tipo de Proyecto
                    </Text>
                    <Field
                      as="select"
                      name="tipo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipo}
                      fontSize={"11pt"}
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        borderWidth: 1,
                        width: "100%",
                      }}
                    >
                      <option value="">Seleccionar estado</option>
                      <option value="Público" name="público">
                        Público
                      </option>
                      <option value="Privado" name="privado">
                        Privado
                      </option>
                    </Field>
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="tipo" />
                    </Box>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Nombre del proyecto
                    </Text>
                    <Input
                      type="text"
                      name="nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nombre}
                      placeholder="Escribe un nombre para tu proyecto"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="nombre" />
                    </Box>

                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Linea de Negocios
                    </Text>

                    <>
                      {result_lineas ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Field
                            as="select"
                            name="lineaNombre"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);

                              // Llama a tu función personalizada aquí.
                              buscarCategorias(
                                e.target.value,
                                e.target.selectedOptions[0].text,
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.lineaNombre}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">
                              Seleccionar una Linea de Negocios
                            </option>
                            {result_lineas.map((linea, index) => {
                              return (
                                <option
                                  key={index}
                                  value={linea.id}
                                  name={linea.nombre}
                                >
                                  {linea.nombre}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay lineas de negocios"
                      )}
                    </>

                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="lineaNombre" />
                    </Box>

                    <>
                      {datosCategorias ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Text
                            fontSize={"sm"}
                            fontWeight={"medium"}
                            fontFamily={fuente}
                            my={2}
                          >
                            Selecciona una Categoría
                          </Text>
                          <Field
                            as="select"
                            name="categoria"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.categoria}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">Selecciona una Categoría</option>
                            {datosCategorias.map((categoria) => {
                              return (
                                <option value={categoria.categoria}>
                                  {categoria.categoria}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay categorias"
                      )}
                    </>

                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="categoria" />
                    </Box>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      ID Oportunidad
                    </Text>
                    <Input
                      type="text"
                      name="oportunidad"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.oportunidad}
                      placeholder="Escribe el ID de la Oportunidad del CRM"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="oportunidad" />
                    </Box>

                    {/* <Center mt={5} >
                                            <Button type="submit" disabled={isSubmitting} bgGradient='linear(to-r, #003049,gray.700,gray.600)' color={'white'} w={'200px'} rounded={'25'}>
                                                Crear
                                            </Button>
                                        </Center> */}
                    <Flex justify={"end"} mt={5}>
                      <Button
                        mr={2}
                        type="submit"
                        disabled={isSubmitting}
                        isLoading={cargando}
                        bg={"orange.400"}
                        color={"white"}
                        fontFamily={fuente}
                      >
                        Guardar
                      </Button>
                      <Button onClick={onCloseProyectoModalEdit}>
                        Cancelar
                      </Button>
                    </Flex>
                  </form>
                </Box>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {/* ModalContact */}
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isContactoModalOpen}
        onClose={onCloseContactoModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize={"15pt"}
            fontWeight={"bold"}
            fontFamily={fuente}
            textTransform={"uppercase"}
          >
            Nuevo contacto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              fontSize={"15px"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Nombre del contacto
            </Text>
            <Input
              onChange={(e) => setNombreContacto(e.target.value)}
              mt={"1"}
              required={true}
              placeholder="Escribe un nombre completo para tu contacto"
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Ingresa el número de WhatsApp
            </Text>
            <PhoneInput
              country={"mx"}
              enableSearch={true}
              value={whatsapp || ""}
              onChange={(whatsapp) => setWhatsApp(whatsapp)}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Teléfono de Oficina
            </Text>
            <PhoneInput
              country={"mx"}
              enableSearch={true}
              value={phone || ""} // Si phone es undefined, establece un valor predeterminado
              onChange={(phone) => setPhone(phone)}
              containerStyle={{ height: "40px" }}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Correo electrónico
            </Text>
            <Input
              type={"email"}
              onChange={(e) => setContactoCorreo(e.target.value.toLowerCase())}
              mt={"1"}
              required={true}
              placeholder="Escribe un correo electrónico."
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Puesto
            </Text>
            <Input
              type={"text"}
              onChange={(e) => setContactoPuesto(e.target.value)}
              mt={"1"}
              required={true}
              placeholder="Escribe un puesto para tú contacto."
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={cargando}
              onClick={handleonclickContactos}
              colorScheme="orange"
              variant={"solid"}
              mr={3}
            >
              Crear
            </Button>
            <Button onClick={onCloseContactoModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* ModalContactEdit */}
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isContactoEditModalOpen}
        onClose={onCloseContactoEditModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize={"15pt"}
            fontWeight={"bold"}
            fontFamily={fuente}
            textTransform={"uppercase"}
          >
            Editar contacto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              fontSize={"15px"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Nombre del contacto
            </Text>
            <Input
              onChange={(e) => setNombreContacto(e.target.value)}
              value={nombreContacto}
              mt={"1"}
              required={true}
              placeholder="Escribe un nombre completo para tú contacto"
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              WhatsApp
            </Text>
            <Input
              onChange={(e) => setWhatsApp(e.target.value)}
              value={whatsapp}
              mt={"1"}
              required={true}
              placeholder="Escribe el número de whatsapp de tú contacto"
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Tel Oficina
            </Text>
            <Input
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              mt={"1"}
              required={true}
              placeholder="Escribe el número de Oficina de tú contacto"
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Correo electrónico
            </Text>
            <Input
              type={"email"}
              onChange={(e) => setContactoCorreo(e.target.value.toLowerCase())}
              value={contactoCorreo}
              mt={"1"}
              required={true}
              placeholder="Escribe un correo electrónico."
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
            <Text
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Puesto
            </Text>
            <Input
              type={"text"}
              onChange={(e) => setContactoPuesto(e.target.value)}
              value={contactoPuesto}
              mt={"1"}
              required={true}
              placeholder="Escribe un puesto para tú contacto."
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={cargando}
              onClick={() => handleonclickContactosEdit(contactoId)}
              colorScheme="orange"
              variant={"solid"}
              mr={3}
            >
              Guardar cambios
            </Button>
            <Button onClick={onCloseContactoEditModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isNotasModalOpen}
        onClose={onCloseNotasModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize={"15pt"}
            fontWeight={"bold"}
            fontFamily={fuente}
            textTransform={"uppercase"}
          >
            Agregar nueva nota
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              fontSize={"16px"}
              fontWeight={"medium"}
              fontFamily={fuente}
              my={2}
              color={"gray.600"}
            >
              Escribe una nota
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={"normal"}
              fontFamily={fuente}
              my={2}
            >
              Para agregar la fecha de hoy agrega el siguiente comando{" "}
              <strong>"/hoy"</strong>
            </Text>
            <Input
              value={notas}
              p={10}
              onChange={(e) => handlerNotas(e.target.value)}
              mt={"1"}
              required={true}
              placeholder="Que esta pasando..."
              fontSize={"11pt"}
              fontFamily={fuente}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={cargando}
              onClick={handleonclickNota}
              colorScheme="orange"
              variant={"solid"}
              mr={3}
            >
              Agregar nota
            </Button>
            <Button onClick={onCloseNotasModal}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type == 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"999"}
            >
              <Header />
            </Box>
            <Blur radius={blurOn ? "1px" : "0"} transition="400ms">
              <Box bg={"white"} h={{ lg: "100vh", base: "100vh" }} mt={"10vh"}>
                <Flex direction={"column"}>
                  <Box bg={"white"} h={{ base: "25vh", lg: "10vh" }}>
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "20%" }}
                        h={{ base: "0", lg: "5vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"pink.400"}
                        w={{ base: "100%", lg: "100%" }}
                        h={{ base: "5vh", lg: "5vh" }}
                      >
                        <Flex direction={"column"}>
                          <Box bg={"white"} h={"12.5vh"}>
                            <Flex direction={"row"} w={"100%"}>
                              <Box bg={"white"} w={"70%"} h={"16.5vh"}>
                                <Flex
                                  direction={"row"}
                                  justifyContent={"center"}
                                >
                                  <Box w={"100%"} justifyContent={"end"}>
                                    <RenderIf isTrue={crm.local == false}>
                                      <Flex>
                                        <Box
                                          alignSelf={"center"}
                                          fontSize={{
                                            lg: "17px",
                                            base: "17px",
                                            "2xl": "17px",
                                          }}
                                          fontWeight={"bold"}
                                          textTransform={"uppercase"}
                                          color={"gray.700"}
                                          fontFamily={fuente}
                                          ml={{ lg: "0", base: "5" }}
                                          mr={2}
                                          mt={{ lg: "8", base: "5" }}
                                        >
                                          {datos.nombre}
                                        </Box>
                                        <Box
                                          alignSelf={"center"}
                                          fontSize={{
                                            lg: "sm",
                                            base: "sm",
                                            "2xl": "sm",
                                          }}
                                          fontFamily={fuente}
                                          fontWeight={"medium"}
                                          color={"blue.500"}
                                          ml={{ lg: "0", base: "10" }}
                                          mt={{ lg: "8", base: "5" }}
                                        >
                                          {datos.username}{" "}
                                        </Box>
                                        <Button
                                          bgGradient="linear(to-r, #e8e8e4,#d8e2dc )"
                                          onClick={onToggle}
                                          size={"sm"}
                                          alignSelf={"center"}
                                          mt={{ lg: "8", base: "5" }}
                                          ml={5}
                                        >
                                          {isColapse ? (
                                            <GrFormUp />
                                          ) : (
                                            <GrFormDown />
                                          )}
                                        </Button>
                                      </Flex>
                                    </RenderIf>
                                  </Box>
                                </Flex>
                              </Box>
                              <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                                <Flex
                                  direction={"column"}
                                  justifyContent={"center"}
                                >
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                    align={"center"}
                                    mt={8}
                                    pr={{ base: 10, lg: 0 }}
                                    ml={5}
                                  >
                                    <Tooltip
                                      label="Crear nuevo proyecto"
                                      aria-label="A tooltip"
                                      placement="right"
                                      borderRadius={"sm"}
                                    >
                                      <Button
                                        colorScheme={"orange"}
                                        onClick={onOpenProyectoModal}
                                      >
                                        {/* <AddIcon  />  */}
                                        Nuevo proyecto
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                </Flex>
                              </Box>
                            </Flex>
                          </Box>

                          <Box bg={"white"} h={"12.5vh"} display={"none"}>
                            <Collapse in={isColapse} animateOpacity>
                              <Center>
                                <Box
                                  w={"100%"}
                                  mt={2}
                                  bg={"white"}
                                  pb={5}
                                  pl={{ base: 5, lg: 0 }}
                                  pr={{ base: 5, lg: 0 }}
                                >
                                  <Flex
                                    direction={"row"}
                                    justifyContent={"start"}
                                  >
                                    <Box
                                      mr={5}
                                      fontSize={{ lg: "15px", base: "15px" }}
                                      textTransform={"uppercase"}
                                      fontFamily={fuente}
                                      fontWeight={"bold"}
                                      color={"gray.700"}
                                      ml={{ lg: "0", base: "5" }}
                                      mb={{ lg: "10", base: "0" }}
                                    >
                                      Dirección
                                      <Flex justify={"start"}>
                                        <Box
                                          h={"2vh"}
                                          justifySelf={"center"}
                                          fontSize={"15px"}
                                          mt={"1"}
                                          mr={3}
                                        >
                                          <BsPinMapFill />
                                        </Box>
                                        <Text
                                          fontWeight={"normal"}
                                          color={"gray.500"}
                                          fontFamily={fuente}
                                          fontSize={"sm"}
                                        >
                                          <Box>
                                            {datos.calle} | # {datos.numero} |
                                          </Box>
                                          {datos.colonia} |{datos.estado} |
                                          {datos.pais} |{datos.codigoPostal}
                                        </Text>
                                      </Flex>
                                    </Box>
                                    <Box
                                      fontSize={{ lg: "15px", base: "15px" }}
                                      textTransform={"uppercase"}
                                      fontFamily={fuente}
                                      fontWeight={"bold"}
                                      color={"gray.700"}
                                      ml={{ lg: "0", base: "5" }}
                                      mb={{ lg: "10", base: "0" }}
                                    >
                                      Estatus
                                      <Flex
                                        justify={"start"}
                                        bg={"green.100"}
                                        px={1}
                                        rounded={"full"}
                                      >
                                        <Tag
                                          size="sm"
                                          colorScheme="green"
                                          borderRadius="full"
                                        >
                                          <TagLabel
                                            fontFamily={fuente}
                                            fontWeight={"medium"}
                                            color={"green.700"}
                                          >
                                            {datos.estatus}
                                          </TagLabel>
                                        </Tag>
                                      </Flex>
                                    </Box>
                                  </Flex>
                                </Box>
                              </Center>
                            </Collapse>
                          </Box>
                        </Flex>
                      </Box>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "20%" }}
                        h={{ base: "0", lg: "25vh" }}
                      ></Box>{" "}
                      {/* Box 3 */}
                    </Flex>
                  </Box>
                  <Box
                    bg={"white"}
                    h={{ base: "65vh", lg: "65vh" }}
                    pl={{ base: 0, lg: 0 }}
                    pr={{ base: 0, lg: 0 }}
                  >
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"white"}
                        w={{ base: "0", lg: "90%" }}
                        h={{ base: "0", lg: "65vh" }}
                      >
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box borderWidth={1} borderRadius={"12px"} py={3}>
                              <Table
                                variant="simple"
                                colorScheme={"gray"}
                                size={"md"}
                                fontFamily={fuente}
                                p={10}
                              >
                                <Thead>
                                  <Tr>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Nombre del proyecto
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      ID Oportunidad
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Cliente
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Linea de Negocio
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Categoria
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Vendedor
                                    </Th>
                                    <Th fontSize={"13px"} fontFamily={fuente}>
                                      Link del proyecto
                                    </Th>
                                    <Th
                                      fontSize={"13px"}
                                      fontFamily={fuente}
                                    ></Th>
                                  </Tr>
                                </Thead>
                                <>
                                  {result ? (
                                    <>
                                      {result.map((proyecto, index) => {
                                        const url = `/vendedor/proyectos/${id}/${proyecto.id}/${nombreCliente}`;
                                        //const urlOportunidad = `${link[0].link}${datos.CARDID}/OppID=${proyecto.oportunidad}`
                                        const linkToUse =
                                          link && link[0] && link[0].link
                                            ? link[0].link
                                            : "";
                                        return (
                                          <Tbody size={"sm"} key={index}>
                                            <Tr
                                              _hover={{ bg: "gray.50" }}
                                              key={index}
                                            >
                                              <Td
                                                fontFamily={fuente}
                                                fontSize={"15px"}
                                                fontWeight={"medium"}
                                                color={"gray.700"}
                                              >
                                                  <Flex direction={"column"}>
                                                  <Link
                                                  href={url}
                                                >
                                                    <Box
                                                      fontFamily={fuente}
                                                      fontSize={"17px"}
                                                      fontWeight={"medium"}
                                                      color={"gray.700"}
                                                      _focus={{ outline: 'none' }}
                                                      mr={"3"}
                                                      _hover={{
                                                        textDecoration: "underline",
                                                        textUnderlineOffset:
                                                          "0.2rem",
                                                        textDecorationStyle: "wavy",
                                                      }}
                                                    >
                                                      {proyecto.nombreProyecto}
                                                    </Box>
                                                    </Link>
                                                    <Flex
                                                      position="relative"
                                                      mt={5}
                                                    >
                                                      <Box
                                                        fontSize={
                                                          "clamp(0.6rem, 0.6rem + 1vw, 0.8rem)"
                                                        }
                                                        color={"gray.500"}
                                                      >
                                                        Tipo de proyecto:
                                                      </Box>
                                                      <Box
                                                        ml={2}
                                                        fontSize={
                                                          "clamp(0.6rem, 0.6rem + 1vw, 0.8rem)"
                                                        }
                                                        color={"orange.500"}
                                                      >
                                                        {proyecto.tipo}
                                                      </Box>
                                                    </Flex>
                                                    <Flex position="relative">
                                                      <Box
                                                        fontSize={
                                                          "clamp(0.6rem, 0.6rem + 1vw, 0.8rem)"
                                                        }
                                                        color={"gray.500"}
                                                      >
                                                        Fecha de Creación:
                                                      </Box>
                                                      <Box
                                                        ml={2}
                                                        fontSize={
                                                          "clamp(0.6rem, 0.6rem + 1vw, 0.8rem)"
                                                        }
                                                      >
                                                        <Moment
                                                          tz="America/Mexico_City"
                                                          locale="ES"
                                                          format="DD/MM/yyyy hh:mm:ss"
                                                        >
                                                          {
                                                            proyecto.fechaCreacion
                                                          }
                                                        </Moment>
                                                      </Box>
                                                    </Flex>
                                                    <Flex direction={'column'}>
                                                      {proyecto.tipo ==
                                                      "Público" ? (
                                                        <>
                                                          <Box
                                                            fontSize={
                                                              "clamp(0.6rem, 0.6rem + 1vw, 0.8rem)"
                                                            }
                                                            color={"gray.500"}
                                                          >
                                                            Dominios autorizados:
                                                          </Box>
                                                          <Box
                                                            fontSize={
                                                              "clamp(0.6rem, 0.6rem + 1vw, 0.8rem)"
                                                            }
                                                          >
                                                            {
                                                              proyecto.dominios?.map((o) => {
                                                                return <Box>{o.value}</Box>
                                                              })
                                                            }
                                                          </Box>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </Flex>
                                                  </Flex>
                                                
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"orange.600"}
                                                fontWeight={"normal"}
                                                fontFamily={fuente}
                                              >
                                                <Link
                                                  href={`${linkToUse}${datos.CARDID}&OppID=${proyecto.oportunidad}`}
                                                  target={"blank"}
                                                  _hover={{
                                                    textDecoration: "underline",
                                                    textUnderlineOffset:
                                                      "0.2rem",
                                                    textDecorationStyle: "wavy",
                                                  }}
                                                >
                                                  <Flex>
                                                    <Box
                                                      fontFamily={fuente}
                                                      fontSize={"21px"}
                                                      fontWeight={"medium"}
                                                      color={"gray.700"}
                                                      mr={"3"}
                                                    ></Box>
                                                    {proyecto.oportunidad}
                                                  </Flex>
                                                </Link>
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                                fontFamily={fuente}
                                              >
                                                {proyecto.nombreEmpresa}
                                              </Td>

                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                                fontFamily={fuente}
                                              >
                                                {proyecto.lineaNegocioNombre}
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                                fontFamily={fuente}
                                              >
                                                {proyecto.categoria}
                                              </Td>

                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                <Text
                                                  fontWeight={"normal"}
                                                  color={"gray.500"}
                                                >
                                                  {proyecto.vendedorName}
                                                </Text>
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                {proyecto.tipo === "Privado" ?
                                                <Button
                                                onClick={() =>
                                                  clipboard.copy(
                                                    "https://portalnc.nctech.com.mx" +
                                                      url,
                                                  )
                                                }
                                              >
                                                <CopyIcon mr={2} />{" "}
                                                {clipboard.copied
                                                  ? "Copiado"
                                                  : "Copiar"}
                                              </Button>
                                                 :
                                                 <Button
                                                  onClick={() =>
                                                    clipboard.copy(
                                                      `https://portalcl.nctech.com.mx/publicbusiness/${id}/${proyecto.id}/${user.id}/${user.username}`
                                                    )
                                                  }
                                                >
                                                  <CopyIcon mr={2} />{" "}
                                                  {clipboard.copied
                                                    ? "Copiado"
                                                    : "Copiar"}
                                                </Button>
                                                 }
                                                
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                <RenderIf
                                                  isTrue={user.type !== 2}
                                                >
                                                  <Menu>
                                                    <MenuButton
                                                      px={4}
                                                      py={2}
                                                      transition="all 0.2s"
                                                      borderRadius="md"
                                                      borderWidth="1px"
                                                      bgGradient="linear(to-l, #e8e8e4,#d8e2dc )"
                                                      _hover={{
                                                        bg: "gray.400",
                                                        color: "white",
                                                      }}
                                                      _expanded={{
                                                        bg: "gray.500",
                                                        color: "white",
                                                      }}
                                                      _focus={{
                                                        boxShadow: "outline",
                                                      }}
                                                      as={Button}
                                                    >
                                                      <HiDotsHorizontal />
                                                    </MenuButton>
                                                    <MenuList>
                                                      <MenuItem
                                                        onClick={() =>
                                                          editar(proyecto.id)
                                                        }
                                                      >
                                                        <EditIcon mr={2} />
                                                        Editar
                                                      </MenuItem>
                                                      <MenuDivider />
                                                      <MenuItem
                                                        onClick={() =>
                                                          eliminar(proyecto.id)
                                                        }
                                                      >
                                                        <DeleteIcon mr={2} />
                                                        Eliminar
                                                      </MenuItem>
                                                    </MenuList>
                                                  </Menu>
                                                </RenderIf>
                                              </Td>
                                            </Tr>
                                          </Tbody>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <Box
                                      fontSize={15}
                                      px={5}
                                      py={5}
                                      color={"gray.500"}
                                      fontWeight={"medium"}
                                      fontFamily={fuente}
                                    >
                                      No hay proyectos
                                    </Box>
                                  )}
                                </>
                              </Table>
                            </Box>
                          </Center>
                          {/* Contactos de la empresa */}
                          <Box my={20}>
                            <Flex justify={"space-between"}>
                              <Flex
                                shadow={"md"}
                                direction={"column"}
                                bg={"gray.50"}
                                my={3}
                                py={5}
                                px={5}
                                w={"100%"}
                                mx={5}
                                rounded={"lg"}
                              >
                                <Box>
                                  <Flex justify={"space-between"}>
                                    <Text
                                      alignSelf={"center"}
                                      fontFamily={fuente}
                                      fontWeight={"medium"}
                                      fontSize={"15pt"}
                                    >
                                      Contactos
                                    </Text>
                                    <Box justifyContent={"center"} ml={3}>
                                      <Tooltip
                                        label="Crear nuevo contacto"
                                        aria-label="A tooltip"
                                        placement="right"
                                        borderRadius={"sm"}
                                      >
                                        <Button
                                          my={2}
                                          colorScheme={"orange"}
                                          onClick={onOpenContactoModal}
                                        >
                                          <AddIcon />
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Flex>
                                  <Box
                                    fontFamily={fuente}
                                    fontSize={"14px"}
                                    fontWeight={"normal"}
                                    color={"gray.500"}
                                  >
                                    Agregar nuevos contactos a{" "}
                                    <strong>{datos.username}</strong>{" "}
                                  </Box>
                                </Box>
                                <>
                                  {result_contactos ? (
                                    <Flex direction={"column"} my={"5"}>
                                      {result_contactos.map(
                                        (contacto, index) => {
                                          return (
                                            <Flex
                                              justify={"space-between"}
                                              key={index}
                                            >
                                              <Box
                                                mr={5}
                                                bg={"whiteAlpha.900"}
                                                mb={3}
                                                rounded={"lg"}
                                                w={"100%"}
                                              >
                                                <Flex
                                                  h={"auto"}
                                                  direction={"row"}
                                                  rounded={"lg"}
                                                  justify={"space-between"}
                                                  overflow={"hidden"}
                                                >
                                                  <Box py={8} px={5}>
                                                    <Box
                                                      fontFamily={fuente}
                                                      fontWeight={"bold"}
                                                      fontSize={"18px"}
                                                      color={"gray.800"}
                                                      mb={3}
                                                    >
                                                      {contacto.nombre}
                                                    </Box>

                                                    <Flex direction={"row"}>
                                                      <Flex
                                                        direction={"column"}
                                                      >
                                                        <Box
                                                          mr={"2"}
                                                          fontFamily={fuente}
                                                          fontSize={"15px"}
                                                          color={"gray.600"}
                                                        >
                                                          <Flex>
                                                            <Box mr={2}>
                                                              <svg
                                                                stroke="#25D366"
                                                                width={"24px"}
                                                                height="24px"
                                                                role="img"
                                                                viewBox="0 0 24 24"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <title>
                                                                  WhatsApp
                                                                </title>
                                                                <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z" />
                                                              </svg>
                                                            </Box>
                                                            <>
                                                              {contacto.whatsapp
                                                                ? `${contacto.whatsapp}`
                                                                : ``}
                                                            </>
                                                          </Flex>
                                                        </Box>
                                                        <Box
                                                          mr={"2"}
                                                          fontFamily={fuente}
                                                          fontSize={"15px"}
                                                          color={"gray.600"}
                                                        >
                                                          <Flex>
                                                            <Box mr={2}>
                                                              <svg
                                                                viewBox="0 0 24 24"
                                                                width={"24px"}
                                                                height="24px"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                <g
                                                                  id="SVGRepo_bgCarrier"
                                                                  stroke-width="0"
                                                                ></g>
                                                                <g
                                                                  id="SVGRepo_tracerCarrier"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                ></g>
                                                                <g id="SVGRepo_iconCarrier">
                                                                  {" "}
                                                                  <path
                                                                    d="M21 10H14.6C14.0399 10 13.7599 10 13.546 9.89101C13.3578 9.79513 13.2049 9.64215 13.109 9.45399C13 9.24008 13 8.96005 13 8.4V5M10 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.07989 21 8.2V17.8C21 18.9201 21 19.4802 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V8.2C3 7.07989 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.49359 5.01338 5.01165 5.00082 6 5.00005M10 5V4.6C10 4.03995 10 3.75992 9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3H7.6C7.03995 3 6.75992 3 6.54601 3.10899C6.35785 3.20487 6.20487 3.35785 6.10899 3.54601C6 3.75992 6 4.03995 6 4.6V5.00005M10 5V15.4C10 15.9601 10 16.2401 9.89101 16.454C9.79513 16.6422 9.64215 16.7951 9.45399 16.891C9.24008 17 8.96005 17 8.4 17H7.6C7.03995 17 6.75992 17 6.54601 16.891C6.35785 16.7951 6.20487 16.6422 6.10899 16.454C6 16.2401 6 15.9601 6 15.4V5.00005M14 14H14.01V13.99H14V14ZM14 17H14.01V17.01H14V17ZM17 17H17.01V17.01H17V17ZM17 14H17.01V14.01H17V14Z"
                                                                    stroke="#000000"
                                                                    stroke-width="2"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                  ></path>{" "}
                                                                </g>
                                                              </svg>
                                                            </Box>
                                                            <>
                                                              {contacto.telOficina
                                                                ? `${contacto.telOficina}`
                                                                : ``}
                                                            </>
                                                          </Flex>
                                                        </Box>
                                                      </Flex>
                                                      <Box
                                                        mr={"2"}
                                                        fontFamily={fuente}
                                                        fontSize={"15px"}
                                                        color={"gray.600"}
                                                      >
                                                        <strong>Email:</strong>{" "}
                                                        {contacto.correo}
                                                      </Box>
                                                      <Box
                                                        fontFamily={fuente}
                                                        fontSize={"15px"}
                                                        color={"gray.600"}
                                                      >
                                                        <strong>Puesto:</strong>{" "}
                                                        {contacto.puesto}
                                                      </Box>
                                                    </Flex>
                                                  </Box>
                                                  <Flex>
                                                    <Tooltip
                                                      label="Editar"
                                                      aria-label="A tooltip"
                                                      placement="right"
                                                      borderRadius={"sm"}
                                                    >
                                                      <Box
                                                        position={"relative"}
                                                        justify={"center"}
                                                        alignSelf={"start"}
                                                        t={0}
                                                        p={2}
                                                        r={3}
                                                        opacity={"0.6"}
                                                        w={"auto"}
                                                        h={"auto"}
                                                        overflow={"hidden"}
                                                      >
                                                        <IconButton
                                                          onClick={() =>
                                                            fetchClienteById(
                                                              contacto.id,
                                                            )
                                                          }
                                                          icon={
                                                            <svg
                                                              width={"20px"}
                                                              height={"20px"}
                                                              viewBox="0 0 24 24"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <g
                                                                id="SVGRepo_bgCarrier"
                                                                stroke-width="0"
                                                              ></g>
                                                              <g
                                                                id="SVGRepo_tracerCarrier"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              ></g>
                                                              <g id="SVGRepo_iconCarrier">
                                                                {" "}
                                                                <path
                                                                  d="M12.4445 19.6875H20.9445M14.4443 5.68747L5.44587 14.6859C4.78722 15.3446 4.26719 16.1441 4.10888 17.062C3.94903 17.9888 3.89583 19.139 4.44432 19.6875C4.99281 20.236 6.14299 20.1828 7.0698 20.0229C7.98772 19.8646 8.78722 19.3446 9.44587 18.6859L18.4443 9.68747M14.4443 5.68747C14.4443 5.68747 17.4443 2.68747 19.4443 4.68747C21.4443 6.68747 18.4443 9.68747 18.4443 9.68747M14.4443 5.68747L18.4443 9.68747"
                                                                  stroke="#000000"
                                                                  stroke-width="1.5"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"
                                                                ></path>{" "}
                                                              </g>
                                                            </svg>
                                                          }
                                                        />
                                                      </Box>
                                                    </Tooltip>
                                                    <Tooltip
                                                      label="Eliminar"
                                                      aria-label="A tooltip"
                                                      placement="right"
                                                      borderRadius={"sm"}
                                                    >
                                                      <Box
                                                        position={"relative"}
                                                        justify={"center"}
                                                        alignSelf={"start"}
                                                        t={0}
                                                        p={2}
                                                        r={3}
                                                        opacity={"0.6"}
                                                        w={"auto"}
                                                        h={"auto"}
                                                        overflow={"hidden"}
                                                      >
                                                        <IconButton
                                                          onClick={() =>
                                                            deleteContact(
                                                              contacto.id,
                                                            )
                                                          }
                                                          bg={"red.200"}
                                                          icon={
                                                            <svg
                                                              width={"20px"}
                                                              height={"20px"}
                                                              viewBox="0 0 24 24"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                              <g
                                                                id="SVGRepo_bgCarrier"
                                                                stroke-width="0"
                                                              ></g>
                                                              <g
                                                                id="SVGRepo_tracerCarrier"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              ></g>
                                                              <g id="SVGRepo_iconCarrier">
                                                                <path
                                                                  fill-rule="evenodd"
                                                                  clip-rule="evenodd"
                                                                  d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z"
                                                                  fill="#000000"
                                                                ></path>
                                                              </g>
                                                            </svg>
                                                          }
                                                        />
                                                      </Box>
                                                    </Tooltip>
                                                  </Flex>
                                                </Flex>
                                              </Box>
                                            </Flex>
                                          );
                                        },
                                      )}
                                    </Flex>
                                  ) : (
                                    "No hay contactos adicionales aún"
                                  )}
                                </>
                              </Flex>
                              <Flex
                                shadow={"md"}
                                direction={"column"}
                                bg={"gray.50"}
                                my={3}
                                py={5}
                                px={5}
                                w={"100%"}
                                mx={5}
                                rounded={"lg"}
                              >
                                <Box>
                                  <Flex justify={"space-between"}>
                                    <Text
                                      alignSelf={"center"}
                                      fontFamily={fuente}
                                      fontWeight={"medium"}
                                      fontSize={"15pt"}
                                    >
                                      Notas generales sobre {nombreCliente}{" "}
                                    </Text>
                                    <Box justifyContent={"center"} ml={3}>
                                      <Tooltip
                                        label="Agregar nueva nota "
                                        aria-label="A tooltip"
                                        placement="right"
                                        borderRadius={"sm"}
                                      >
                                        <Button
                                          my={2}
                                          colorScheme={"orange"}
                                          onClick={onOpenNotasModal}
                                        >
                                          <AddIcon />
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Flex>
                                </Box>
                                <>
                                  {result_notas ? (
                                    <Flex
                                      direction={"column"}
                                      my={"5"}
                                      rounded={"lg"}
                                    >
                                      {result_notas.map((nota, index) => {
                                        return (
                                          <Box key={index}>
                                            <Flex
                                              justify={"space-between"}
                                              my={3}
                                            >
                                              <Box
                                                p={5}
                                                fontFamily={fuente}
                                                fontSize={"15px"}
                                                color={"gray.900"}
                                                bgGradient="linear(to-r, #e8e8e4,#d8e2dc )"
                                                rounded={"full"}
                                              >
                                                {nota.nota}
                                              </Box>
                                              <Box
                                                fontFamily={fuente}
                                                fontSize={"13px"}
                                                color={"gray.500"}
                                              >
                                                hace{" "}
                                                <Moment
                                                  fromNow
                                                  ago
                                                  tz="America/Mexico_City"
                                                  local="es"
                                                >
                                                  {nota.fechaCreacion}
                                                </Moment>
                                              </Box>
                                            </Flex>
                                          </Box>
                                        );
                                      })}
                                    </Flex>
                                  ) : (
                                    "No hay notas aún"
                                  )}
                                </>
                              </Flex>
                            </Flex>
                          </Box>
                        </Box>
                      </Box>
                      {/* Tabla para movil*/}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Blur>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

//REDUX
const mapStateProps = (state) => ({
  link: state.linkReducer.link,
  crm: state.crmReducer.local,
  proyectos: state.proyectosReducer.proyectos,
  proyecto: state.proyectosReducer.proyecto,
});

const style = {
  fonts: {
    transition: "font-size 0.5s ease-in-out",
    fontSize: "clamp(1rem, 8vw, 1rem)",
  },
};

const Detalles = connect(mapStateProps, {
  get_link,
  get_crm,
  get_proyectos,
  delete_proyecto,
  edit_proyecto,
  get_proyecto_by_id,
})(_Detalles);
export default Detalles;
