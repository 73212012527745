import React, { useState, useRef, useEffect } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  //Table,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  IconButton,
  Select,
  useToast,
} from "@chakra-ui/react";
import { IoMdShareAlt } from "react-icons/io";
import { CloseIcon } from "@chakra-ui/icons";
import Header from "../../Header";
import useUser from "../../context/useUser";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuVendedor";
import UploadImageToS3WithReactS3 from "../../UploadFiletoS3";
import UploadVideoToS3 from "../../UploadFiletoS3Video";
import "moment-timezone";
import { nanoid } from "nanoid";
import { RenderIf } from "../../../hooks/renderIF";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import { useClipboard } from "../../../hooks/copy";
import { Table } from "antd";

import moment from "moment";
import { useDebouncedCallback } from "use-debounce";

import {
  FileEdit,
  FileVideo,
  FileText,
  Copy,
  CopyCheck,
  MoreHorizontal,
  Paperclip,
} from "lucide-react";
const Attachments = () => {
  const form = useRef();
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  const { user } = useUser();
  const {
    isOpen: isOpenFile,
    onOpen: onOpenFile,
    onClose: onCloseFile,
  } = useDisclosure();
  const {
    isOpen: isOpenShare,
    onOpen: onOpenShare,
    onClose: onCloseShare,
  } = useDisclosure();
  const {
    isOpen: isOpenFileVideo,
    onOpen: onOpenFileVideo,
    onClose: onCloseFileVideo,
  } = useDisclosure();
  const {
    isOpen: isOpenFileEdit,
    onOpen: onOpenFileEdit,
    onClose: onCloseFileEdit,
  } = useDisclosure();
  const [urlPresentacion] = useState();
  const [nombrePropuesta, setNombrePropuesta] = useState();
  const [categoria, setCategoria] = useState();
  const [value, setValue] = useState();
  const [valueComentarios, setValueComentarios] = useState("");
  const [setColor] = useState("gray.50");
  const [setColorBoton] = useState("blue");
  const [cargando, setCargando] = useState(false);
  const [urlT, setUrlT] = useState();
  const [tipo, setTipo] = useState();
  const [setDownload] = useState(false);
  const [setPrint] = useState(false);
  const [documento, setDocumento] = useState();
  const [fuente] = useState(
    "Montserrat",
  );
  const clipboard = useClipboard({ timeout: 2000 });
  const { Column, ColumnGroup } = Table;
  const [isActive, setIsActive] = useState(false);
  const [inputBusqueda, setInputBusqueda] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const toast = useToast();
  const [idToEdit, setIdToEdit] = useState(null);
  const [nombreArchivoToEdit, setNombreArchivoToEdit] = useState(null);
  const [categoriaToEdit, setCategoriaToEdit] = useState(null);
  const [listCategorias, setListCategorias] = useState([]);
  const [tipoAplicacion, setTipoAplicacion] = useState([]);
  const [tipoArchivo, setTipoArchivo] = useState([]);
  const [tipoAplicacionBusqueda, setTipoAplicacionBusqueda] = useState([]);
  const [categoriaBusqueda, setCategoriaBusqueda] = useState([]);
  const [tipoArchivoBusqueda, setTipoArchivoBusqueda] = useState([]);
  const [listTipoAplicacion, setListTipoAplicacion] = useState([]);

  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchLineas();
    fetchTipoAplicacion();
  }, []);
  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const handleTableChange = (pagination, filters, sorter) => {
    // Actualiza el tamaño de página y la página actual cuando el usuario realiza cambios en la paginación
    setPageSize(pagination.pageSize);
    setCurrentPage(pagination.current);
  };

  const iconRotation = isHovered ? "rotate(180deg)" : "rotate(0)";

  const buttonStyles = {
    rounded: "full",
    transition: "transform 0.3s ease", // Agregar transición
    transform: iconRotation, // Aplicar la rotación
  };
  const handleKeyPress = (event) => {
    setInputValue(event.target.value);
    if (event.target.value === "") {
      debouncedUpdates(false, event.target.value);
    }
    if (event.target.value !== "") {
      debouncedUpdates(true, event.target.value);
      //buscar(event.target.value)
    } else {
      debouncedUpdates(false, event.target.value);
    }
  };
  const debouncedUpdates = useDebouncedCallback(async (e, event) => {
    buscar(event);
    setInputBusqueda(e);
  }, 1000);
  const debouncedUpdatesTipo = useDebouncedCallback(async (e, event) => {
    buscar(null, null, event);
    setInputBusqueda(e);
  }, 1000);
  const debouncedUpdatesTipoArchivo = useDebouncedCallback(async (e, event) => {
    buscar(null, null, null, event);
    setInputBusqueda(e);
  }, 1000);
  const debouncedUpdatesCategoria = useDebouncedCallback(async (e, event) => {
    buscar(null, event, null);
    setInputBusqueda(e);
  }, 1000);
  const handlerComentariosValor = (e) => {
    setValueComentarios(e.target.value);
  };
  const handlerValor = (e) => {
    setValue(e.target.value);
  };
  async function fetchPresentaciones() {
    // async function fetchPresentaciones() {
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/documentos`;
    //const urlC = 'http://localhost:8080/api/documentos'
    // const urlC = 'https://afe71e20-857c-4233-8591-3b8ecc476897-us-east-1.apps.astra.datastax.com/api/rest/v2/namespaces/sales/collections/attachmens'
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      //params: queryParams // Pasar los parámetros de consulta en la URL
    });

    return ventasData;
  }
  async function buscar(nombre, categoria, tipo, tipoArchivo) {
    try {
      const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/documentos`;
      // const urlC = 'https://afe71e20-857c-4233-8591-3b8ecc476897-us-east-1.apps.astra.datastax.com/api/rest/v2/namespaces/sales/collections/attachmens'
      const ventasData = await axios(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        //params: queryParams // Pasar los parámetros de consulta en la URL
      });
      // return ventasData;
      if (nombre === "") {
        refetch();
      }
      if (nombre) {
        const presentacionesFiltradas = ventasData.data.filter((presentacion) =>
          presentacion.nombreArchivo
            .toLowerCase()
            .includes(nombre.toLowerCase()),
        );
        if (presentacionesFiltradas.length > 0) {
          setDatos(presentacionesFiltradas);
        }
      }
      if (categoria) {
        // console.log("Entra", ventasData.data.filter((presentacion) =>
        //     presentacion.tipoAplicacion && tipo && presentacion.tipoAplicacion.includes(tipo)
        // ))
        const presentacionesFiltradas = ventasData.data.filter(
          (presentacion) =>
            presentacion.categoria &&
            categoria &&
            presentacion.categoria.includes(categoria),
        );

        if (presentacionesFiltradas.length > 0) {
          setDatos(presentacionesFiltradas);
        }
      }

      if (tipo) {
        // console.log("Entra", ventasData.data.filter((presentacion) =>
        //     presentacion.tipoAplicacion && tipo && presentacion.tipoAplicacion.includes(tipo)
        // ))
        const presentacionesFiltradas = ventasData.data.filter(
          (presentacion) =>
            presentacion.tipoAplicacion &&
            tipo &&
            presentacion.tipoAplicacion.includes(tipo),
        );

        if (presentacionesFiltradas.length > 0) {
          setDatos(presentacionesFiltradas);
        }
      }
      if (tipoArchivo) {
        // console.log("Entra", ventasData.data.filter((presentacion) =>
        //     presentacion.tipoAplicacion && tipo && presentacion.tipoAplicacion.includes(tipo)
        // ))
        const presentacionesFiltradas = ventasData.data.filter(
          (presentacion) =>
            presentacion.tipoArchivo &&
            tipoArchivo &&
            presentacion.tipoArchivo.includes(tipoArchivo),
        );

        if (presentacionesFiltradas.length > 0) {
          setDatos(presentacionesFiltradas);
        }
      }
    } catch (error) {
      console.error("Error al buscar las presentaciones:", error);
      // Manejar el error adecuadamente
    }
  }
  const compartir = async () => {
    if (!value) {
      setCargando(true);
      setColor("red.100");
      setColorBoton("red");
      setTimeout(() => {
        setColor("blue.50");
        setColorBoton("blue");
        setValue("");
        setValueComentarios("");
        setCargando(false);
      }, 3000);
    } else {
      setCargando(true);
      const url = urlT;
      const encodedUrl = url.replace(/ /g, "%20");
      const nombreArchivo = encodedUrl.substring(
        encodedUrl.lastIndexOf("/") + 1,
      );

      const urlPublic = `https://portalcl.nctech.com.mx/public/${nombreArchivo}/${tipo}/${documento}/${user.id.toString()}`;
      const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/attachments`;
      const body = {
        templateId: "d-11627cc3f1cf4486b154451aac923497",
        to: form.current[0].value.toString(),
        url: urlPublic,
        comentarios: valueComentarios,
        subject: "Archivos compartidos por NC Tech.",
        vendedor: user.username,
      };
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(body),
      });
      if (response.status == 201) {
        setColor("green.100");
        setColorBoton("green");
        setCargando(false);
        setTimeout(() => {
          setColorBoton("blue");
          setValueComentarios("");
          onCloseShare();
          setValue("");
        }, 2000);
      }
    }
  };
  const crearPresentacion = async (data) => {
    try {
      const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/documentos`;
      const response = await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombreArchivo: nombrePropuesta,
          fechaCreacion: Date.now(),
          status: "Activo",
          url: urlPresentacion,
          provider: "google",
        }),
      });

      if (response.status === 500) {
        // Aquí puedes realizar el segundo intento, si es necesario
        // No es necesario redefinir la constante urlC

        // Por ejemplo:
        const response2 = await fetch(urlC, {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nombreArchivo: nombrePropuesta,
            fechaCreacion: Date.now(),
            status: "Activo",
            url: urlPresentacion,
            provider: "google",
          }),
        });

        onCloseFile();
        return response2;
      }

      // Si la solicitud se realizó correctamente, devuelve la respuesta
      return response;
    } catch (error) {
      console.error(error);
      throw new Error("Error al crear la presentación");
    }
  };
  const duplicarPresentacion = async (data) => {
    //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/documentos`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-Cassandra-Token": tokenCasandra,
      },
      body: JSON.stringify({
        nombreArchivo: data.nombreArchivo + "_" + nanoid(),
        fechaCreacion: Date.now(),
        status: data.status,
        url: data.url,
        provider: data.provider,
        tipo: data.tipo,
      }),
    });
  };
  const eliminarPresentacion = async (id) => {
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/eliminarDocumento/${id}`;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    setTimeout(() => {
      refetch();
    }, 500);
  };
  async function actualizar() {
    // //const urlC = `${urlCassandra}collections/attachmens${user.id.toString()}?page-size=20`
    // const urlC = `${urlCassandra}collections/attachmens?page-size=20`
    // const ventasData = await axios(urlC, {
    //     method: 'GET',
    //     headers: {
    //         'accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         'X-Cassandra-Token': tokenCasandra
    //     }
    // })
    // console.log(ventasData.response)
    // const presentaciones =  await Object.keys(ventasData.data.data).map((item) => ({id: item, ...ventasData.data.data[item]}))
    // return presentaciones

    // async function fetchPresentaciones() {
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/documentos`;
    // const urlC = 'https://afe71e20-857c-4233-8591-3b8ecc476897-us-east-1.apps.astra.datastax.com/api/rest/v2/namespaces/sales/collections/attachmens'
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      //params: queryParams // Pasar los parámetros de consulta en la URL
    });

    return ventasData;
  }
  const mutation_duplicar = useMutation(duplicarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("DOCS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_delete = useMutation(eliminarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("DOCS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation = useMutation(crearPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("DOCS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const { isLoading, isFetching, data, refetch } = useQuery(
    "DOCS",
    fetchPresentaciones,
    {
      onSuccess: (data) => {
        //console.log("data:", data.nombre)
        setDatos(data.data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }
  const handleUpload = async () => {
    mutation.mutate();
  };
  const handleDelete = async (id) => {
    mutation_delete.mutate(id);
  };
  const handlerNombrePropuesta = (e) => {
    setNombrePropuesta(e);
  };
  const handlerCategoria = (e) => {
    setCategoria(e);
  };
  const handlerTipoAplicacion = (e) => {
    setTipoAplicacion(e);
  };
  const handlerTipoArchivo = (e) => {
    setTipoArchivo(e);
  };
  const handlerTipoAplicacionBusqueda = (event) => {
    if (event) {
      setTipoAplicacionBusqueda(event);

      if (event === "") {
        debouncedUpdatesTipo(false, event);
      } else {
        debouncedUpdatesTipo(true, event);
        //buscar(event.target.value)
      }
    } else {
      console.error("Evento de búsqueda incorrecto:", event);
    }
  };
  const handlerCategoriaBusqueda = (event) => {
    if (event) {
      setCategoriaBusqueda(event);

      if (event === "") {
        debouncedUpdatesCategoria(false, event);
      } else {
        debouncedUpdatesCategoria(true, event);
        //buscar(event.target.value)
      }
    } else {
      console.error("Evento de búsqueda incorrecto:", event);
    }
  };
  const handlerTipoArchivoBusqueda = (event) => {
    if (event) {
      setTipoArchivoBusqueda(event);

      if (event === "") {
        debouncedUpdatesTipoArchivo(false, event);
      } else {
        debouncedUpdatesTipoArchivo(true, event);
        //buscar(event.target.value)
      }
    } else {
      console.error("Evento de búsqueda incorrecto:", event);
    }
  };
  const duplicar = (nombreArchivo, status, url, fechaCreacion, provider) => {
    mutation_duplicar.mutate({
      nombreArchivo,
      status,
      url,
      fechaCreacion,
      provider,
    });
  };
  const Share = (id, a, b) => {
    onOpenShare();
    setUrlT(a);
    setTipo(b);
    setDocumento(id);
  };
  const handleDownloadToggle = async (id, d) => {
    try {
      const url = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/actualizarDocumento/${id}`;
      // const url = `${urlCassandra}collections/attachmens${user.id.toString()}/${id}`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          download: !d,
        }),
      });
      setDownload(!d);
    } catch (error) {
      console.error(
        "Error al actualizar el valor de download en la base de datos:",
        error,
      );
    }
    refetch();
  };
  const handlePrintToggle = async (id, p) => {
    setIsActive(!isActive);
    const impresion = !p;
    try {
      const url = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/actualizarDocumentoPrint/${id}`;
      //const url = `${urlCassandra}collections/attachmens${user.id.toString()}/${id}`;
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          print: impresion,
        }),
      });
      setPrint(!p);
    } catch (error) {
      console.error(
        "Error al actualizar el valor de download en la base de datos:",
        error,
      );
    }
    refetch();
  };
  const handleUpdateMetadataAttachments = async (id) => {
    if (tipo == "video") {
      //console.log("Video")
      try {
        const url = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/updateMetadataAttachments/${id}`;
        //const url = `${urlCassandra}collections/attachmens${user.id.toString()}/${id}`;
        const response = await fetch(url, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nombreArchivo: nombrePropuesta
              ? nombrePropuesta
              : nombreArchivoToEdit,
            categoria: categoria,
            tipoAplicacion: tipoAplicacion,
            tipoArchivo: "Video",
          }),
        });
      } catch (error) {
        console.error(
          "Error al actualizar el valor de download en la base de datos:",
          error,
        );
      }
      refetch();
      onCloseFileEdit();
      toast({
        title: "Success",
        description: "Archivo actualizado correctamente",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      console.log("No Video");
      try {
        const url = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/updateMetadataAttachments/${id}`;
        //const url = `${urlCassandra}collections/attachmens${user.id.toString()}/${id}`;
        const response = await fetch(url, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nombreArchivo: nombrePropuesta
              ? nombrePropuesta
              : nombreArchivoToEdit,
            categoria: categoria,
            tipoAplicacion: tipoAplicacion,
            tipoArchivo: tipoArchivo,
          }),
        });
      } catch (error) {
        console.error(
          "Error al actualizar el valor de download en la base de datos:",
          error,
        );
      }
      refetch();
      onCloseFileEdit();
      toast({
        title: "Success",
        description: "Archivo actualizado correctamente",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const Limpiar = () => {
    setInputBusqueda();
    setCategoriaBusqueda();
    setTipoAplicacionBusqueda(null);

    refetch();
    setInputValue("");
  };
  const EditModal = (id, nombre, categoria, tipoArchivo, tipo) => {
    setIdToEdit(id);
    setNombreArchivoToEdit(nombre);
    setCategoriaToEdit(categoria);
    setTipoAplicacion(tipoArchivo);
    setTipo(tipo);
    onOpenFileEdit();
  };
  async function fetchLineas() {
    //const urlC = `${urlCassandra}collections/lineas?page-size=20`
    const urlC = "https://servicesemail-production-899b13b908d7.herokuapp.com/api/lineas";
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        // 'X-Cassandra-Token': tokenCasandra
      },
    });
    const lineas = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    setListCategorias(lineas);

    // return lineas
  }
  async function fetchTipoAplicacion() {
    //const urlC = `${urlCassandra}collections/lineas?page-size=20`
    const urlC =
      "https://servicesemail-production-899b13b908d7.herokuapp.com/api/getTipoAplicacion";
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        // 'X-Cassandra-Token': tokenCasandra
      },
    });
    const Tipos = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    setListTipoAplicacion(Tipos);

    // return lineas
  }

  return (
    <>
      <>
        <Modal
          size={"xl"}
          closeOnOverlayClick={false}
          isOpen={isOpenFile}
          onClose={onCloseFile}
          isCentered
          zIndex={"9999"}
        >
          <div>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                fontSize={"lg"}
                fontWeight={"bold"}
                fontFamily={"Montserrat"}
              >
                Subir nuevo archivo
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Escribe un nombre para tu archivo
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerNombrePropuesta(e.target.value)}
                    type={"text"}
                    placeholder={"Archivo de Prueba"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  />
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona una categoría
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerCategoria(e.target.value)}
                    type={"text"}
                    placeholder={"Selecciona aquí..."}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    {listCategorias.map((o, i) => {
                      const valor = o.nombre;
                      return (
                        <option key={i} value={valor}>
                          {o.nombre}
                        </option>
                      );
                    })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona tipo de aplicación
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerTipoAplicacion(e.target.value)}
                    type={"text"}
                    placeholder={"Selecciona aquí..."}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    {listTipoAplicacion.map((o, i) => {
                      const valor = o.nombre;
                      return (
                        <option key={i} value={valor}>
                          {o.nombre}
                        </option>
                      );
                    })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona tipo de archivo
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerTipoArchivo(e.target.value)}
                    type={"text"}
                    placeholder={"Selecciona aquí..."}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    <option value="Documento">Documento</option>
                    <option value="Presentación">Presentación</option>
                    <option value="Contrato">Contrato</option>
                  </Select>
                  <UploadImageToS3WithReactS3
                    nombre={nombrePropuesta}
                    cerrar={onCloseFile}
                    query={actualizar}
                    categoria={categoria}
                    tipoAplicacion={tipoAplicacion}
                    tipoArchivo={tipoArchivo}
                  />
                </Box>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </div>
        </Modal>
      </>
      <>
        <Modal
          size={"xl"}
          closeOnOverlayClick={false}
          isOpen={isOpenFileEdit}
          onClose={onCloseFileEdit}
          isCentered
          zIndex={"9999"}
        >
          <div>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                fontSize={"lg"}
                fontWeight={"bold"}
                fontFamily={"Montserrat"}
              >
                Edita tu Archivo
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Escribe un nuevo nombre para tu archivo
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerNombrePropuesta(e.target.value)}
                    type={"text"}
                    placeholder={nombreArchivoToEdit}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  />
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona una categoría
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerCategoria(e.target.value)}
                    type={"text"}
                    placeholder={categoria}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    {listCategorias.map((o, i) => {
                      const valor = o.nombre;
                      return (
                        <option key={i} value={valor}>
                          {o.nombre}
                        </option>
                      );
                    })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona tipo de aplicación
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerTipoAplicacion(e.target.value)}
                    type={"text"}
                    placeholder={tipoAplicacion}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    {listTipoAplicacion.map((o, i) => {
                      const valor = o.nombre;
                      return (
                        <option key={i} value={valor}>
                          {o.nombre}
                        </option>
                      );
                    })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona tipo de archivo
                  </Box>
                  <>
                    {tipo === "video" ? (
                      <Input
                        bg={"white"}
                        type={"text"}
                        placeholder={tipo}
                        fontSize={"sm"}
                        fontWeight={"normal"}
                        color={"gray.800"}
                        fontFamily={"Montserrat"}
                      />
                    ) : (
                      <Select
                        bg={"white"}
                        onChange={(e) => handlerTipoArchivo(e.target.value)}
                        type={"text"}
                        placeholder={"Selecciona aquí..."}
                        fontSize={"sm"}
                        fontWeight={"normal"}
                        color={"gray.800"}
                        fontFamily={"Montserrat"}
                      >
                        <option value="Documento">Documento</option>
                        <option value="Presentación">Presentación</option>
                        <option value="Contrato">Contrato</option>
                      </Select>
                    )}
                  </>
                  <Button
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                    mt={5}
                    bg={"orange"}
                    variant={"outline"}
                    onClick={() =>
                      handleUpdateMetadataAttachments(idToEdit, "video")
                    }
                  >
                    {" "}
                    Guardar cambios{" "}
                  </Button>
                </Box>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </div>
        </Modal>
      </>
      <>
        <Modal
          size={"lg"}
          isOpen={isOpenShare}
          onClose={onCloseShare}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontFamily={fuente}
              fontSize={"15pt"}
              fontWeight={"medium"}
            ></ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Center>
                <Box zIndex={1} py={3} w="100%">
                  <Box
                    p="20px"
                    color="gray.500"
                    mt="4"
                    bg="#BFEDF6"
                    rounded="15"
                    shadow={"sm"}
                    blur="10"
                  >
                    <Center>
                      <Flex direction={"column"}>
                        <Box
                          mt={5}
                          blur={"8px"}
                          fontSize={"25pt"}
                          color={"gray.800"}
                          fontWeight={"bold"}
                          fontFamily={fuente}
                        >
                          ¡Compártelo! con alguien más
                        </Box>
                        <Box
                          fontSize={"sm"}
                          color={"gray.700"}
                          fontWeight={"normal"}
                          fontFamily={fuente}
                          mb={5}
                        >
                          Comparte este contenido escribiendo su cuenta de
                          correo electrónico.
                        </Box>

                        <form ref={form}>
                          <Flex direction={"column"}>
                            <Box>
                              <Box
                                fontSize={"sm"}
                                color={"gray.700"}
                                fontWeight={"medium"}
                                fontFamily={fuente}
                              >
                                Correo electrónico del destinatario
                              </Box>
                              <Input
                                rounded={15}
                                mb={5}
                                type={"email"}
                                placeholder={"Eje. example@example.com"}
                                bg={"white"}
                                fontSize={"sm"}
                                color={"gray.800"}
                                fontWeight={"normal"}
                                fontFamily={fuente}
                                value={value}
                                onChange={(e) => handlerValor(e)}
                              />
                              <Box>
                                <Box
                                  fontSize={"sm"}
                                  color={"gray.700"}
                                  fontWeight={"normal"}
                                  fontFamily={fuente}
                                >
                                  Comentarios
                                </Box>
                                <Input
                                  rounded={15}
                                  mb={5}
                                  type={"text"}
                                  placeholder={"Eje. aquí"}
                                  bg={"white"}
                                  fontSize={"sm"}
                                  color={"gray.800"}
                                  fontWeight={"normal"}
                                  fontFamily={fuente}
                                  value={valueComentarios}
                                  onChange={(e) => handlerComentariosValor(e)}
                                />
                              </Box>

                              <Flex w={"100%"} justify={"center"}>
                                <Button
                                  align={"center"}
                                  _hover={{ bg: "orange.400" }}
                                  w="50%"
                                  bg={"orange.500"}
                                  isLoading={cargando}
                                  onClick={() => compartir()}
                                  mx={1}
                                >
                                  <IoMdShareAlt size={"24px"} color={"white"} />
                                  <Box ml={"1"} color={"white"}>
                                    Compartir
                                  </Box>
                                </Button>
                              </Flex>
                            </Box>
                          </Flex>
                        </form>
                      </Flex>
                    </Center>
                  </Box>
                </Box>
              </Center>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        <Modal
          size={"xl"}
          closeOnOverlayClick={false}
          isOpen={isOpenFileVideo}
          onClose={onCloseFileVideo}
          isCentered
          zIndex={"9999"}
        >
          <div>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                fontSize={"lg"}
                fontWeight={"medium"}
                fontFamily={"Montserrat"}
              >
                Subir nuevo video
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Escribe un nombre para tu video
                  </Box>
                  <Input
                    bg={"white"}
                    onChange={(e) => handlerNombrePropuesta(e.target.value)}
                    type={"text"}
                    placeholder={"Nombre de tu video"}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  />
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona una categoría
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerCategoria(e.target.value)}
                    type={"text"}
                    placeholder={"Selecciona aquí..."}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    {listCategorias.map((o, i) => {
                      const valor = o.nombre;
                      return (
                        <option key={i} value={valor}>
                          {o.nombre}
                        </option>
                      );
                    })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona tipo de aplicación
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerTipoAplicacion(e.target.value)}
                    type={"text"}
                    placeholder={"Selecciona aquí..."}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    {listTipoAplicacion.map((o, i) => {
                      const valor = o.nombre;
                      return (
                        <option key={i} value={valor}>
                          {o.nombre}
                        </option>
                      );
                    })}
                  </Select>
                  <Box
                    my={1}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"Montserrat"}
                  >
                    Selecciona tipo de archivo
                  </Box>
                  <Select
                    bg={"white"}
                    onChange={(e) => handlerTipoArchivo(e.target.value)}
                    type={"text"}
                    placeholder={"Selecciona aquí..."}
                    fontSize={"sm"}
                    fontWeight={"normal"}
                    color={"gray.800"}
                    fontFamily={"Montserrat"}
                  >
                    <option value="Video">Video</option>
                  </Select>
                  {/* <Box my={1} fontSize={'sm'} fontWeight={'medium'} color={'gray.500'} fontFamily={'Montserrat'}>Escribe una categoría para tu video</Box>
                                    <Input bg={'white'} onChange={(e) => handlerCategoria(e.target.value)} type={'text'} placeholder={'Escribe una categoría'} fontSize={'sm'} fontWeight={'normal'} color={'gray.800'} fontFamily={'Montserrat'} /> */}
                  <UploadVideoToS3
                    nombre={nombrePropuesta}
                    cerrar={onCloseFileVideo}
                    query={actualizar}
                    categoria={categoria}
                    tipoAplicacion={tipoAplicacion}
                  />
                </Box>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </div>
        </Modal>
      </>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type == 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>
            <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "15vh", lg: "15vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "21px",
                                      base: "23px",
                                      "2xl": "21px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"Montserrat"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "6", base: "5" }}
                                  >
                                    Biblioteca de Archivos Públicos
                                  </Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"Montserrat"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"Montserrat"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí podras subir y ver los archivos de tu
                                    compañia
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"gray.200"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  <Tooltip
                                    label="Subir nuevo archivo"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Button
                                      bgGradient="linear(to-r, #fca311,#fb8500 )"
                                      onClick={onOpenFile}
                                      mr={1}
                                      _hover={{
                                        bgGradient:
                                          "linear(to-t, #fca311,#fb8500 )",
                                      }}
                                    >
                                      <svg
                                        width={"24px"}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M20 10.9696L11.9628 18.5497C10.9782 19.4783 9.64274 20 8.25028 20C6.85782 20 5.52239 19.4783 4.53777 18.5497C3.55315 17.6211 3 16.3616 3 15.0483C3 13.7351 3.55315 12.4756 4.53777 11.547L12.575 3.96687C13.2314 3.34779 14.1217 3 15.05 3C15.9783 3 16.8686 3.34779 17.525 3.96687C18.1814 4.58595 18.5502 5.4256 18.5502 6.30111C18.5502 7.17662 18.1814 8.01628 17.525 8.63535L9.47904 16.2154C9.15083 16.525 8.70569 16.6989 8.24154 16.6989C7.77738 16.6989 7.33224 16.525 7.00403 16.2154C6.67583 15.9059 6.49144 15.4861 6.49144 15.0483C6.49144 14.6106 6.67583 14.1907 7.00403 13.8812L14.429 6.88674"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>{" "}
                                        </g>
                                      </svg>
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    label="Subir nuevo video"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Button
                                      bgGradient="linear(to-l,#fca311,#fb8500  )"
                                      onClick={onOpenFileVideo}
                                      _hover={{
                                        bgGradient:
                                          "linear(to-t, #fca311,#fb8500 )",
                                      }}
                                    >
                                      <svg
                                        width={"24px"}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          id="SVGRepo_bgCarrier"
                                          stroke-width="0"
                                        ></g>
                                        <g
                                          id="SVGRepo_tracerCarrier"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></g>
                                        <g id="SVGRepo_iconCarrier">
                                          {" "}
                                          <path
                                            d="M4 15V9C4 7.89543 4.89543 7 6 7H13C14.1046 7 15 7.89543 15 9V15C15 16.1046 14.1046 17 13 17H6C4.89543 17 4 16.1046 4 15Z"
                                            stroke="#fff"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>{" "}
                                          <path
                                            d="M18.3753 8.29976L15.3753 10.6998C15.1381 10.8895 15 11.1768 15 11.4806V12.5194C15 12.8232 15.1381 13.1105 15.3753 13.3002L18.3753 15.7002C19.0301 16.2241 20 15.7579 20 14.9194V9.08062C20 8.24212 19.0301 7.77595 18.3753 8.29976Z"
                                            stroke="#fff"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></path>{" "}
                                        </g>
                                      </svg>
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      {datos ? (
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box
                              borderWidth={1}
                              borderRadius={"12px"}
                              zIndex={1}
                              py={3}
                            >
                              <Flex direction={"row"} w={"100%"}>
                                <Flex direction={"column"} w={"33%"}>
                                  <Box mx={2} my={2}>
                                    <Flex>
                                      <Box w={"100%"}>
                                        <Box
                                          my={1}
                                          fontSize={"sm"}
                                          fontWeight={"medium"}
                                          color={"gray.500"}
                                          fontFamily={"Montserrat"}
                                        >
                                          Buscar por nombre de archivo.
                                        </Box>
                                        <Input
                                          _focus={{
                                            boxShadow: "none",
                                            outline: "none",
                                          }}
                                          type="text"
                                          value={inputValue}
                                          autoFocus
                                          onChange={handleKeyPress}
                                          bg={"white"}
                                          fontFamily={fuente}
                                          placeholder={
                                            "Escribe aquí para buscar..."
                                          }
                                        />
                                      </Box>
                                      <RenderIf isTrue={inputBusqueda == true}>
                                        <Box position={"relative"} right={10}>
                                          <Tooltip
                                            label="Limpiar busqueda"
                                            aria-label="A tooltip"
                                            placement="right"
                                            borderRadius={"sm"}
                                          >
                                            <IconButton
                                              onClick={() => Limpiar()}
                                              ml={2}
                                              bg={"red.200"}
                                              shadow={"lg"}
                                              onMouseEnter={handleHover}
                                              isRound={true}
                                              onMouseLeave={handleHover}
                                            >
                                              <CloseIcon
                                                w={4}
                                                h={4}
                                                sx={buttonStyles}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      </RenderIf>
                                    </Flex>
                                  </Box>
                                </Flex>
                                <Flex direction={"column"} w={"33%"}>
                                  <Box mx={2} my={2}>
                                    <Flex>
                                      <Box w={"100%"}>
                                        <Box
                                          my={1}
                                          fontSize={"sm"}
                                          fontWeight={"medium"}
                                          color={"gray.500"}
                                          fontFamily={"Montserrat"}
                                        >
                                          Buscar por categoría
                                        </Box>
                                        <Select
                                          bg={"white"}
                                          onChange={(e) =>
                                            handlerCategoriaBusqueda(
                                              e.target.value,
                                            )
                                          }
                                          type={"text"}
                                          placeholder={"Filtro por categoría"}
                                          fontSize={"sm"}
                                          fontWeight={"normal"}
                                          color={"gray.800"}
                                          fontFamily={"Montserrat"}
                                        >
                                          {listCategorias.map((o, i) => {
                                            const valor = o.nombre;
                                            return (
                                              <option key={i} value={valor}>
                                                {o.nombre}
                                              </option>
                                            );
                                          })}
                                        </Select>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </Flex>
                                <Flex direction={"column"} w={"33%"}>
                                  <Box mx={2} my={2}>
                                    <Flex>
                                      <Box w={"100%"}>
                                        <Box
                                          my={1}
                                          fontSize={"sm"}
                                          fontWeight={"medium"}
                                          color={"gray.500"}
                                          fontFamily={"Montserrat"}
                                        >
                                          Buscar por tipo de aplicación
                                        </Box>
                                        <Select
                                          bg={"white"}
                                          onChange={(e) =>
                                            handlerTipoAplicacionBusqueda(
                                              e.target.value,
                                            )
                                          }
                                          type={"text"}
                                          placeholder={"Filtro Tipo Aplicación"}
                                          fontSize={"sm"}
                                          fontWeight={"normal"}
                                          color={"gray.800"}
                                          fontFamily={"Montserrat"}
                                        >
                                          {listTipoAplicacion.map((o, i) => {
                                            const valor = o.nombre;
                                            return (
                                              <option key={i} value={valor}>
                                                {o.nombre}
                                              </option>
                                            );
                                          })}
                                        </Select>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </Flex>
                                <Flex direction={"column"} w={"33%"}>
                                  <Box mx={2} my={2}>
                                    <Flex>
                                      <Box w={"100%"}>
                                        <Box
                                          my={1}
                                          fontSize={"sm"}
                                          fontWeight={"medium"}
                                          color={"gray.500"}
                                          fontFamily={"Montserrat"}
                                        >
                                          Buscar por tipo de archivo
                                        </Box>
                                        <Select
                                          bg={"white"}
                                          onChange={(e) =>
                                            handlerTipoArchivoBusqueda(
                                              e.target.value,
                                            )
                                          }
                                          type={"text"}
                                          placeholder={"Filtro Tipo Archivo"}
                                          fontSize={"sm"}
                                          fontWeight={"normal"}
                                          color={"gray.800"}
                                          fontFamily={"Montserrat"}
                                        >
                                          <option value="Documento">
                                            Documento
                                          </option>
                                          <option value="Presentación">
                                            Presentación
                                          </option>
                                          <option value="Contrato">
                                            Contrato
                                          </option>
                                          <option value="Video">Video</option>
                                        </Select>
                                      </Box>
                                    </Flex>
                                  </Box>
                                </Flex>
                              </Flex>

                              <Table
                                dataSource={datos}
                                bordered
                                fontFamily={fuente}
                                size="small"
                                pagination={{
                                  pageSize,
                                  current: currentPage,
                                  pageSizeOptions: [
                                    "5",
                                    "10",
                                    "20",
                                    "50",
                                    "100",
                                  ],
                                  onChange: handleTableChange,
                                }}
                              >
                                <Column
                                  title="Nombre del archivo"
                                  dataIndex="nombreArchivo"
                                  key="nombreArchivo"
                                  render={(text, record, index) => {
                                    const url = record.url;
                                    const encodedUrl = url.replace(/ /g, "%20");
                                    const nombreArchivo = encodedUrl.substring(
                                      encodedUrl.lastIndexOf("/") + 1,
                                    );
                                    const URLCOMPUESTA = `https://portalcl.nctech.com.mx/public/${nombreArchivo}/${record.tipo}/${record.id}/${user.id.toString()}`;
                                    return (
                                      <>
                                        {record.provider === "s3" ? (
                                          <Link
                                            href={URLCOMPUESTA}
                                            target="_blank"
                                          >
                                            <Flex>
                                              <Box
                                                fontSize="21px"
                                                fontWeight="medium"
                                                mr="3"
                                                selfAlign={"center"}
                                              >
                                                <Paperclip size={"14px"} />
                                              </Box>
                                              {record.nombreArchivo}
                                            </Flex>
                                          </Link>
                                        ) : (
                                          <Link
                                            href={URLCOMPUESTA}
                                            target="_blank"
                                          >
                                            <Flex>
                                              <Box
                                                fontSize="21px"
                                                fontWeight="medium"
                                                color="gray.700"
                                                mr="3"
                                              >
                                                <Paperclip size={"14px"} />
                                              </Box>
                                              {record.nombreArchivo}
                                            </Flex>
                                          </Link>
                                        )}
                                      </>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />
                                <Column
                                  title="Link"
                                  dataIndex="link"
                                  key="link"
                                  render={(text, record, index) => {
                                    const url = record.url;
                                    const encodedUrl = url.replace(/ /g, "%20");
                                    const nombreArchivo = encodedUrl.substring(
                                      encodedUrl.lastIndexOf("/") + 1,
                                    );
                                    const URLCOMPUESTA = `https://portalcl.nctech.com.mx/public/${nombreArchivo}/${record.tipo}/${record.id}/${user.id.toString()}`;

                                    return (
                                      <Tooltip
                                        label="Copiar"
                                        aria-label="A tooltip"
                                        placement="right"
                                        borderRadius={"sm"}
                                      >
                                        <Button
                                          variant="outline"
                                          color={"black"}
                                          _hover={{
                                            bgGradient:
                                              "linear(to-r, #e8e8e4,#d8e2dc )",
                                          }}
                                          onClick={() =>
                                            clipboard.copy(URLCOMPUESTA)
                                          }
                                        >
                                          {clipboard.copied ? (
                                            <CopyCheck
                                              color="green"
                                              size={"14px"}
                                            />
                                          ) : (
                                            <Copy size={"14px"} />
                                          )}
                                        </Button>
                                      </Tooltip>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />

                                <Column
                                  title="Tipo de Archivo"
                                  dataIndex="tipo"
                                  key="tipo"
                                  render={(text, record, index) => {
                                    return (
                                      <Tag bgGradient="linear(to-r, #e8e8e4,#d8e2dc )">
                                        {record.tipoArchivo === "Video" ? (
                                          <FileVideo
                                            size={"16px"}
                                            style={{ marginRight: "4px" }}
                                          />
                                        ) : (
                                          <FileText
                                            size={"16px"}
                                            style={{ marginRight: "4px" }}
                                          />
                                        )}
                                        {record.tipoArchivo}
                                      </Tag>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />
                                <Column
                                  title="Categoría"
                                  dataIndex="categoria"
                                  key="categoria"
                                  render={(text, record, index) => {
                                    return (
                                      <>
                                        {record.categoria ? (
                                          <Tag bgGradient="linear(to-r, #e8e8e4,#d8e2dc )">
                                            {record.categoria}
                                          </Tag>
                                        ) : (
                                          <Tag bg="orange.100">
                                            Sín categoría
                                          </Tag>
                                        )}
                                      </>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />
                                <Column
                                  title="Tipo Aplicación"
                                  dataIndex="tipoAplicacion"
                                  key="tipoAplicacion"
                                  render={(text, record, index) => {
                                    return (
                                      <>
                                        {record.tipoAplicacion ? (
                                          <Tag bgGradient="linear(to-r, #e8e8e4,#d8e2dc )">
                                            {record.tipoAplicacion}
                                          </Tag>
                                        ) : (
                                          <Tag bg="orange.100">
                                            Sín tipo de aplicación
                                          </Tag>
                                        )}
                                      </>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />

                                {/* <Column
                                                            title="Fecha de Creación"
                                                            dataIndex="fecha"
                                                            key="fecha"
                                                            render={(text, record, index) => {
                                                               
                                                                return (
                                                                    <Moment tz="America/Mexico_City" locale='ES' format='DD/MM/yyyy hh:mm:ss'>{record.fechaCreacion}</Moment>
                                                                );
                                                            }}
                                                            fontFamily={fuente}
                                                            /> */}
                                <Column
                                  title="Fecha de Creación"
                                  dataIndex="fechaCreacion"
                                  key="fechaCreacion"
                                  render={(text, record) => (
                                    <span>
                                      {moment(record.fechaCreacion).format(
                                        "DD/MM/yyyy hh:mm:ss",
                                      )}
                                    </span>
                                  )}
                                  // filterDropdown={({ setSelectedKeys, confirm, clearFilters }) => (
                                  //     <div style={{ padding: 8 }}>
                                  //     <DatePicker
                                  //         onChange={(date) => setSelectedKeys(date ? [date] : [])}
                                  //         format="DD/MM/yyyy"
                                  //         placeholder='selecciona una fecha'
                                  //     />
                                  //     <Button type="primary" onClick={confirm} size="small" style={{ marginRight: 8 }} p={2}>
                                  //         Filtrar
                                  //     </Button>
                                  //     <Button onClick={clearFilters} size="small" p={2}>
                                  //         Restablecer
                                  //     </Button>
                                  //     </div>
                                  // )}
                                  // filterIcon={<FilterOutlined />}
                                  // onFilter={(value, record) =>
                                  //     moment(record.fechaCreacion).isSame(value, 'day')
                                  // }
                                />

                                <Column
                                  title=""
                                  dataIndex="acciones"
                                  key="acciones"
                                  render={(text, record, index) => {
                                    const check = record.print;

                                    return (
                                      <>
                                        {/* {record.tipo == 'propuesta' ? */}
                                        {record.tipo == "*" ? (
                                          <></>
                                        ) : (
                                          <Flex justify={"space-between"}>
                                            <>
                                              <RenderIf
                                                isTrue={
                                                  record.download.toString() ===
                                                  "true"
                                                }
                                              >
                                                <Checkbox
                                                  mr={2}
                                                  defaultChecked={
                                                    record.download
                                                  }
                                                  fontSize={"12px"}
                                                  color={"gray.600"}
                                                  fontWeight={"normal"}
                                                  onChange={() =>
                                                    handleDownloadToggle(
                                                      record.id,
                                                      record.download,
                                                      false,
                                                    )
                                                  }
                                                >
                                                  Descargar
                                                </Checkbox>
                                              </RenderIf>
                                              <RenderIf
                                                isTrue={
                                                  record.download.toString() ===
                                                  "false"
                                                }
                                              >
                                                <Checkbox
                                                  mr={2}
                                                  defaultChecked={
                                                    record.download
                                                  }
                                                  fontSize={"12px"}
                                                  color={"gray.600"}
                                                  fontWeight={"normal"}
                                                  onChange={() =>
                                                    handleDownloadToggle(
                                                      record.id,
                                                      record.download,
                                                      true,
                                                    )
                                                  }
                                                >
                                                  Descargar
                                                </Checkbox>
                                              </RenderIf>
                                            </>

                                            <>
                                              <RenderIf
                                                isTrue={
                                                  record.print.toString() ===
                                                  "true"
                                                }
                                              >
                                                <Checkbox
                                                  defaultChecked={record.print}
                                                  fontSize={"12px"}
                                                  color={"gray.600"}
                                                  fontWeight={"normal"}
                                                  onChange={() =>
                                                    handlePrintToggle(
                                                      record.id,
                                                      record.print,
                                                      false,
                                                    )
                                                  }
                                                >
                                                  Imprimir
                                                </Checkbox>
                                              </RenderIf>
                                              <RenderIf
                                                isTrue={
                                                  record.print.toString() ===
                                                  "false"
                                                }
                                              >
                                                <Checkbox
                                                  defaultChecked={record.print}
                                                  fontSize={"12px"}
                                                  color={"gray.600"}
                                                  fontWeight={"normal"}
                                                  onChange={() =>
                                                    handlePrintToggle(
                                                      record.id,
                                                      record.print,
                                                      true,
                                                    )
                                                  }
                                                >
                                                  Imprimir
                                                </Checkbox>
                                              </RenderIf>
                                            </>
                                          </Flex>
                                        )}
                                      </>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />

                                <Column
                                  title=""
                                  dataIndex="acciones"
                                  key="acciones"
                                  render={(text, record, index) => {
                                    return (
                                      <Menu>
                                        <MenuButton
                                          as={Button}
                                          rightIcon={
                                            <MoreHorizontal size={"14px"} />
                                          }
                                        ></MenuButton>
                                        <MenuList>
                                          <MenuItem
                                            onClick={() =>
                                              EditModal(
                                                record.id,
                                                record.nombreArchivo,
                                                record.categoria,
                                                record.tipoAplicacion,
                                                record.tipo,
                                              )
                                            }
                                          >
                                            <FileEdit />
                                            <Box ml={2} size={"24px"}>
                                              Editar
                                            </Box>
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              Share(
                                                record.id,
                                                record.url,
                                                record.tipo,
                                              )
                                            }
                                          >
                                            <svg
                                              width={"24px"}
                                              height={"24px"}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g
                                                id="SVGRepo_bgCarrier"
                                                stroke-width="0"
                                              ></g>
                                              <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></g>
                                              <g id="SVGRepo_iconCarrier">
                                                {" "}
                                                <path
                                                  d="M20 13V17.5C20 20.5577 16 20.5 12 20.5C8 20.5 4 20.5577 4 17.5V13M12 3L12 15M12 3L16 7M12 3L8 7"
                                                  stroke="#000000"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>{" "}
                                              </g>
                                            </svg>
                                            <Box ml={2}>Compartir</Box>
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() =>
                                              duplicar(
                                                record.nombreArchivo,
                                                record.status,
                                                record.url,
                                                record.fechaCreacion,
                                                record.provider,
                                              )
                                            }
                                          >
                                            <svg
                                              width={"24px"}
                                              height={"24px"}
                                              fill="#000000"
                                              viewBox="0 0 256 256"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g
                                                id="SVGRepo_bgCarrier"
                                                stroke-width="0"
                                              ></g>
                                              <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></g>
                                              <g id="SVGRepo_iconCarrier">
                                                {" "}
                                                <path
                                                  d="M47.81 91.725c0-8.328 6.539-15.315 15.568-15.33 9.03-.016 14.863.015 14.863.015s-.388-8.9-.388-15.978c0-7.08 6.227-14.165 15.262-14.165s92.802-.26 101.297.37c8.495.63 15.256 5.973 15.256 14.567 0 8.594-.054 93.807-.054 101.7 0 7.892-7.08 15.063-15.858 15.162-8.778.1-14.727-.1-14.727-.1s.323 9.97.323 16.094c0 6.123-7.12 15.016-15.474 15.016s-93.117.542-101.205.542c-8.088 0-15.552-7.116-15.207-15.987.345-8.871.345-93.58.345-101.906zm46.06-28.487l-.068 98.164c0 1.096.894 1.99 1.999 1.984l95.555-.51a2.007 2.007 0 0 0 1.998-2.01l-.064-97.283a2.01 2.01 0 0 0-2.01-2.007l-95.4-.326a1.99 1.99 0 0 0-2.01 1.988zM63.268 95.795l.916 96.246a2.007 2.007 0 0 0 2.02 1.982l94.125-.715a3.976 3.976 0 0 0 3.953-4.026l-.137-11.137s-62.877.578-71.054.578-15.438-7.74-15.438-16.45c0-8.71.588-68.7.588-68.7.01-1.1-.874-1.99-1.976-1.975l-9.027.13a4.025 4.025 0 0 0-3.97 4.067z"
                                                  fill-rule="evenodd"
                                                ></path>{" "}
                                              </g>
                                            </svg>
                                            <Box ml={2}>Duplicar</Box>
                                          </MenuItem>

                                          <MenuItem
                                            onClick={() =>
                                              handleDelete(record.id)
                                            }
                                          >
                                            <svg
                                              width={"24px"}
                                              height={"24px"}
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g
                                                id="SVGRepo_bgCarrier"
                                                stroke-width="0"
                                              ></g>
                                              <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></g>
                                              <g id="SVGRepo_iconCarrier">
                                                {" "}
                                                <path
                                                  d="M4 7H20"
                                                  stroke="#000000"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>{" "}
                                                <path
                                                  d="M6 7V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V7"
                                                  stroke="#000000"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>{" "}
                                                <path
                                                  d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                                                  stroke="#000000"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>{" "}
                                              </g>
                                            </svg>
                                            <Box ml={2}>Eliminar</Box>
                                          </MenuItem>
                                        </MenuList>
                                      </Menu>
                                    );
                                  }}
                                  fontFamily={fuente}
                                />
                              </Table>
                            </Box>
                          </Center>
                        </Box>
                      ) : (
                        "Aún no cuentas con Archivos"
                      )}
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
export default Attachments;
