import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Box,
  Center,
  Flex,
  Link,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Input,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import Header from "../../Header";
import useUser from "../../context/useUser";
import { useQuery } from "react-query";
import MenuVendedor from "../Utilidades/MenuVendedor";
import { HiDocumentText } from "react-icons/hi";
import Moment from "react-moment";
import "moment-timezone";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
import { RenderIf } from "../../../hooks/renderIF";
import Blur from "react-css-blur";
import { useClipboard } from "../../../hooks/copy";
import { connect } from "react-redux";
import { get_link, get_crm } from "../../../actions";
import {
  get_proyectos,
  delete_proyecto,
  edit_proyecto,
  get_proyecto_by_id,
} from "../../../actions/proyectos";
import { Space, Table } from "antd";
import "antd/dist/antd.css";
import { HiDotsHorizontal } from "react-icons/hi";
import { useDebouncedCallback } from "use-debounce";
import { Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Button as ButtonAnt, Input as InputAnt } from "antd";

const _HomeProyectos = (props) => {
  const {
    isOpen: isProyectoOpenEdit,
    onOpen: onOpenProyectoModalEdit,
    onClose: onCloseProyectoModalEdit,
  } = useDisclosure();

  const { user } = useUser();

  const [fuente] = useState(
    "ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
  );

  const [blurOn] = useState(false);
  const clipboard = useClipboard({ timeout: 2000 });
  const { link, proyectos, get_proyectos } = props || {};
  const { Column } = Table;
  const [isHovered, setIsHovered] = useState(false);

  //*Constantes de formulario de edicion**************

  const [nombre, setNombre] = useState("");
  const [categoria, setCategoria] = useState("");
  const [tipo, setTipo] = useState();
  const [lineaID, setLineaID] = useState("");
  const [lineaNombre, setLineaNombre] = useState("");
  const [oportunidad, setOportunidad] = useState("");
  const [datosCategorias, setDatosCategorias] = useState([]);
  const [cargando] = useState(false);
  const { proyecto } = props || {};
  const [setFilteredInfo] = useState({});
  //Fin***********************************************

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  //Fin de Declaración de contantantes****************

  //Inicio Filter Search
  //#region
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    handleSearch(selectedKeys, confirm, dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <InputAnt
          ref={searchInput}
          placeholder={`Escribe aquí para buscar...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <ButtonAnt
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </ButtonAnt>
          <ButtonAnt
            onClick={() =>
              handleReset(clearFilters, selectedKeys, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </ButtonAnt>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  //Fin Filter Search
  //Filtro en las columnas

  const handleChangeFilter = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };

  const filtersTipo = proyectos.map((o) => ({
    text: o.tipo,
    value: o.tipo,
  }));

  const filterslineaNegocioNombre = proyectos.map((o) => ({
    text: o.lineaNegocioNombre,
    value: o.lineaNegocioNombre,
  }));

  const filterscategoria = proyectos.map((o) => ({
    text: o.categoria,
    value: o.categoria,
  }));

  const filtersestatus = proyectos.map((o) => ({
    text: o.estatus,
    value: o.estatus,
  }));

  //End Filtros en las columnas

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const iconRotation = isHovered ? "rotate(180deg)" : "rotate(0)";

  const buttonStyles = {
    rounded: "full",
    transition: "transform 0.3s ease", // Agregar transición
    transform: iconRotation, // Aplicar la rotación
  };

  const debouncedUpdates = useDebouncedCallback(async (e) => {
    get_proyectos(e);
  }, 1000);

  const debouncedUpdatesClose = useDebouncedCallback(async (e) => {
    onCloseProyectoModalEdit();
  }, 1000);
  const debouncedEdit = useDebouncedCallback(async (e) => {
    setTimeout(() => {
      if (proyecto != undefined) {
        setTipo(proyecto[0].tipo);
        setNombre(proyecto[0].nombreProyecto);
        setLineaID(proyecto[0].lineaNegocio);
        setCategoria(proyecto[0].categoria);
        setOportunidad(proyecto[0].oportunidad);
      }
    }, 750);
    setTimeout(() => {
      onOpenProyectoModalEdit();
    }, 750);
  }, 1000);

  useEffect(() => {
    props.get_link();
  }, []);

  async function fetchLineas() {
    const urlC = "https://servicesemail-production-899b13b908d7.herokuapp.com/api/lineas";
    const lineasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const lineas = await Object.keys(lineasData.data).map((item) => ({
      id: item,
      ...lineasData.data[item],
    }));
    return lineas;
  }
  async function fetchCategorias(idCat) {
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/categorias/${idCat}`;
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const categorias = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    setDatosCategorias(categorias);
    return categorias;
  }

  async function fetchProyectos() {
    try {
      get_proyectos(user.id.toString());
    } catch (error) {
      console.log(error);
    }
  }

  useQuery("PROYECTOS", fetchProyectos);
  const query_lineas = useQuery("LINEAS", fetchLineas);
  const result_lineas = query_lineas.data;
  const nuevo = () => {
    window.location = "/vendedor";
  };
  function buscarCategorias(id, nombre) {
    setLineaID(id);
    setLineaNombre(nombre);
    fetchCategorias(id);
  }
  const eliminar = async (proyecto) => {
    props.delete_proyecto(proyecto);
    debouncedUpdates(user.id.toString());
  };
  const editar = async (proyecto) => {
    debouncedEdit(proyecto);
    props.get_proyecto_by_id(proyecto);
  };

  const update = async (proyecto) => {
    debouncedUpdates(user.id.toString());
    debouncedUpdatesClose();
  };
  console.log(proyectos);
  return (
    <>
      {/* actualizar proyecto*/}
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isProyectoOpenEdit}
        onClose={onCloseProyectoModalEdit}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize={"15pt"}
            fontWeight={"bold"}
            fontFamily={fuente}
            textTransform={"uppercase"}
          >
            Editar Proyecto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                tipo: tipo,
                nombre: nombre,
                lineaNombre: lineaID,
                categoria: categoria,
                oportunidad: oportunidad,
              }}
              validate={(values) => {
                const errors = {};

                if (!values.tipo) {
                  errors.tipo = "Este campo es requerido.";
                }
                if (!values.nombre) {
                  errors.nombre = "Este campo es requerido.";
                }
                if (!values.lineaNombre) {
                  errors.lineaNombre = "Este campo es requerido.";
                }
                if (!values.categoria) {
                  errors.categoria = "Este campo es requerido.";
                }
                if (!values.oportunidad) {
                  errors.oportunidad = "Este campo es requerido.";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTipo(values.tipo);
                setNombre(values.nombre);
                setCategoria(values.categoria);
                setOportunidad(values.oportunidad);
                props.edit_proyecto(
                  proyecto[0].id,
                  values.nombre,
                  values.oportunidad,
                  values.tipo,
                  lineaID,
                  lineaNombre,
                  values.categoria,
                  values.nombreCliente,
                );
                update(proyecto);
                setSubmitting(false);
                resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Box mt={10}>
                  <form onSubmit={handleSubmit}>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Tipo de Proyecto
                    </Text>
                    <Field
                      as="select"
                      name="tipo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tipo}
                      fontSize={"11pt"}
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        borderWidth: 1,
                        width: "100%",
                      }}
                    >
                      <option value="">Seleccionar estado</option>
                      <option value="Público" name="público">
                        Público
                      </option>
                      <option value="Privado" name="privado">
                        Privado
                      </option>
                    </Field>
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="tipo" />
                    </Box>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Nombre del proyecto
                    </Text>
                    <Input
                      type="text"
                      name="nombre"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nombre}
                      placeholder="Escribe un nombre para tu proyecto"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="nombre" />
                    </Box>

                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      Linea de Negocios
                    </Text>

                    <>
                      {result_lineas ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Field
                            as="select"
                            name="lineaNombre"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);

                              // Llama a tu función personalizada aquí.
                              buscarCategorias(
                                e.target.value,
                                e.target.selectedOptions[0].text,
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.lineaNombre}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">
                              Seleccionar una Linea de Negocios
                            </option>
                            {result_lineas.map((linea, index) => {
                              return (
                                <option
                                  key={index}
                                  value={linea.id}
                                  name={linea.nombre}
                                >
                                  {linea.nombre}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay lineas de negocios"
                      )}
                    </>

                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="lineaNombre" />
                    </Box>

                    <>
                      {datosCategorias ? (
                        <>
                          {/* <Select placeholder='Selecciona un valor' onChange={(e) => buscarCategorias(e.target.value, e.target.selectedOptions[0].text)} fontFamily={fuente}> */}
                          <Text
                            fontSize={"sm"}
                            fontWeight={"medium"}
                            fontFamily={fuente}
                            my={2}
                          >
                            Selecciona una Categoría
                          </Text>
                          <Field
                            as="select"
                            name="categoria"
                            onChange={(e) => {
                              // Llama a handleChange para que Formik maneje el cambio de valor.
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.categoria}
                            fontSize={"11pt"}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              borderWidth: 1,
                              width: "100%",
                            }}
                          >
                            <option value="">Selecciona una Categoría</option>
                            {datosCategorias.map((categoria) => {
                              return (
                                <option value={categoria.categoria}>
                                  {categoria.categoria}
                                </option>
                              );
                            })}
                          </Field>
                        </>
                      ) : (
                        "No hay categorias"
                      )}
                    </>

                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="categoria" />
                    </Box>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      fontFamily={fuente}
                      my={2}
                    >
                      ID Oportunidad
                    </Text>
                    <Input
                      type="text"
                      name="oportunidad"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.oportunidad}
                      placeholder="Escribe el ID de la Oportunidad del CRM"
                      fontSize={"11pt"}
                      bg={"white"}
                    />
                    <Box color={"red.500"} fontSize={"10pt"}>
                      <ErrorMessage name="oportunidad" />
                    </Box>

                    {/* <Center mt={5} >
                                            <Button type="submit" disabled={isSubmitting} bgGradient='linear(to-r, #003049,gray.700,gray.600)' color={'white'} w={'200px'} rounded={'25'}>
                                                Crear
                                            </Button>
                                        </Center> */}
                    <Flex justify={"end"} mt={5}>
                      <Button
                        mr={2}
                        type="submit"
                        disabled={isSubmitting}
                        isLoading={cargando}
                        bg={"orange.400"}
                        color={"white"}
                        fontFamily={fuente}
                      >
                        Guardar
                      </Button>
                      <Button onClick={onCloseProyectoModalEdit}>
                        Cancelar
                      </Button>
                    </Flex>
                  </form>
                </Box>
              )}
            </Formik>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type == 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"999"}
            >
              <Header />
            </Box>
            <Blur radius={blurOn ? "1px" : "0"} transition="400ms">
              <Box bg={"white"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
                <Flex direction={"column"}>
                  <Box bg={"white"} mx={5}>
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box bg={"white"} w={{ base: "100%", lg: "100%" }}>
                        <Flex direction={"column"}>
                          <Box bg={"gray.300"} h={"12.5vh"}>
                            <Flex direction={"row"} w={"100%"}>
                              <Box bg={"white"} w={"70%"} h={"16.5vh"}>
                                <Flex
                                  direction={"row"}
                                  justifyContent={"center"}
                                >
                                  <Box
                                    w={"100%"}
                                    justifyContent={"end"}
                                    fontFamily={fuente}
                                    fontWeight={"bold"}
                                    ml={20}
                                    mt={20}
                                    style={{ fontSize: "1v" }}
                                  >
                                    Listado de Proyectos
                                  </Box>
                                </Flex>
                              </Box>

                              <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                                <Flex
                                  direction={"column"}
                                  justifyContent={"center"}
                                >
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                    align={"center"}
                                    mt={8}
                                    pr={{ base: 10, lg: 0 }}
                                    ml={5}
                                  >
                                    <Tooltip
                                      label="Crear nuevo proyecto"
                                      aria-label="A tooltip"
                                      placement="right"
                                      borderRadius={"sm"}
                                    >
                                      <Button
                                        colorScheme={"orange"}
                                        onClick={() => nuevo()}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleHover}
                                      >
                                        <AddIcon mr={2} sx={buttonStyles} />
                                        Crear proyecto
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                  <Box
                                    bg={"white"}
                                    w={"100%"}
                                    h={"4.16vh"}
                                  ></Box>
                                </Flex>
                              </Box>
                            </Flex>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                  <Box
                    bg={"white"}
                    h={{ base: "65vh", lg: "65vh" }}
                    pl={{ base: 0, lg: 0 }}
                    pr={{ base: 0, lg: 0 }}
                  >
                    <Flex direction={{ base: "column", lg: "row" }}>
                      <Box
                        display={{ base: "none", lg: "flex" }}
                        bg={"white"}
                        w={{ base: "0", lg: "5%" }}
                        h={{ base: "0", lg: "65vh" }}
                      ></Box>{" "}
                      {/* Box 1 */}
                      <Box
                        bg={"white"}
                        w={{ base: "0", lg: "90%" }}
                        h={{ base: "0", lg: "65vh" }}
                      >
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box borderWidth={1} borderRadius={"12px"} py={3}>
                              {proyectos ? (
                                <>
                                  <Table
                                    dataSource={proyectos}
                                    bordered
                                    pagination={{
                                      pageSize: 5,
                                    }}
                                    onChange={handleChangeFilter}
                                  >
                                    <Column
                                      title="Nombre del proyecto"
                                      dataIndex="Nombre del proyecto"
                                      key="nombreProyecto"
                                      {...getColumnSearchProps(
                                        "nombreProyecto",
                                      )}
                                      render={(text, record, index) => (
                                        <Link
                                          href={`/vendedor/proyectos/${record.idProyecto}/${record.id}/${record.nombreEmpresa}`}
                                          _hover={{
                                            textDecoration: "underline",
                                            textUnderlineOffset: "0.2rem",
                                            textDecorationStyle: "wavy",
                                          }}
                                        >
                                          <Flex>
                                            <Box
                                              fontFamily={fuente}
                                              fontSize={"21px"}
                                              fontWeight={"medium"}
                                              color={"gray.700"}
                                              mr={"3"}
                                            >
                                              <HiDocumentText />
                                            </Box>
                                            {record.nombreProyecto}
                                          </Flex>
                                        </Link>
                                      )}
                                    />

                                    <Column
                                      title="ID Oportunidad"
                                      dataIndex="2"
                                      key="oportunidad"
                                      {...getColumnSearchProps("oportunidad")}
                                      render={(text, record, index) => {
                                        const linkToUse =
                                          link && link[0] && link[0].link
                                            ? link[0].link
                                            : ""; // Verificación de link

                                        return (
                                          <Link
                                            href={`${linkToUse}${record.idProyecto}&OppID=${record.oportunidad}`}
                                            target="_blank"
                                            _hover={{
                                              textDecoration: "underline",
                                              textUnderlineOffset: "0.2rem",
                                              textDecorationStyle: "wavy",
                                            }}
                                          >
                                            <Flex>
                                              <Box
                                                fontFamily={fuente}
                                                fontSize="21px"
                                                fontWeight="medium"
                                                color="gray.700"
                                                mr="3"
                                              ></Box>
                                              {record.oportunidad}
                                            </Flex>
                                          </Link>
                                        );
                                      }}
                                    />
                                    <Column
                                      title="Empresa"
                                      dataIndex="nombreEmpresa"
                                      key="nombreEmpresa"
                                      {...getColumnSearchProps("nombreEmpresa")}
                                      render={(text, record, index) => {
                                        // Verificación de link

                                        return (
                                          <Link
                                            href={`https://portalnc.nctech.com.mx/vendedor/clientes/detalles/${record.idProyecto}/${record.nombreEmpresa}`}
                                            _hover={{
                                              textDecoration: "underline",
                                              textUnderlineOffset: "0.2rem",
                                              textDecorationStyle: "wavy",
                                            }}
                                          >
                                            <Flex>
                                              <Box
                                                fontFamily={fuente}
                                                fontSize="21px"
                                                fontWeight="medium"
                                                color="gray.700"
                                                mr="3"
                                              ></Box>
                                              {record.nombreEmpresa}
                                            </Flex>
                                          </Link>
                                        );
                                      }}
                                    />
                                    <Column
                                      title="Tipo del proyecto"
                                      dataIndex="tipo"
                                      key="tipo"
                                      filters={filtersTipo}
                                      onFilter={(value, record) =>
                                        record.tipo.includes(value)
                                      }
                                    />
                                    <Column
                                      title="Linea de Negocio"
                                      dataIndex="lineaNegocioNombre"
                                      key="lineaNegocioNombre"
                                      filters={filterslineaNegocioNombre}
                                      onFilter={(value, record) =>
                                        record.lineaNegocioNombre.includes(
                                          value,
                                        )
                                      }
                                    />
                                    <Column
                                      title="Categoria"
                                      dataIndex="categoria"
                                      key="categoria"
                                      filters={filterscategoria}
                                      onFilter={(value, record) =>
                                        record.categoria.includes(value)
                                      }
                                    />
                                    <Column
                                      title="Estatus"
                                      dataIndex="estatus"
                                      key="estatus"
                                      filters={filtersestatus}
                                      onFilter={(value, record) =>
                                        record.estatus.includes(value)
                                      }
                                    />

                                    <Column
                                      title="Fecha de Creación"
                                      dataIndex="fechaCreacion"
                                      key="fechaCreacion"
                                      render={(text, record, index) => (
                                        <Moment
                                          tz="America/Mexico_City"
                                          locale="ES"
                                          format="DD/MM/yyyy hh:mm:ss"
                                        >
                                          {record.fechaCreacion}
                                        </Moment>
                                      )}
                                    />
                                    <Column
                                      title="Vendedor"
                                      dataIndex="vendedorName"
                                      key="vendedorName"
                                    />

                                    <Column
                                      title="Link"
                                      dataIndex="link"
                                      key="link"
                                      render={(text, record, index) => (
                                        <Button
                                          onClick={() =>
                                            clipboard.copy(
                                              "https://portalnc.nctech.com.mx" +
                                                `/vendedor/proyectos/${record.idProyecto}/${record.id}`,
                                            )
                                          }
                                        >
                                          <CopyIcon mr={2} />{" "}
                                          {clipboard.copied
                                            ? "Copiado"
                                            : "Copiar"}
                                        </Button>
                                      )}
                                    />
                                    <Column
                                      title="Acciones"
                                      dataIndex="link"
                                      key="link"
                                      render={(text, record, index) => (
                                        <Menu>
                                          <MenuButton
                                            px={4}
                                            py={2}
                                            transition="all 0.2s"
                                            borderRadius="md"
                                            borderWidth="1px"
                                            bgGradient="linear(to-l, #e8e8e4,#d8e2dc )"
                                            _hover={{
                                              bg: "gray.400",
                                              color: "white",
                                            }}
                                            _expanded={{
                                              bg: "gray.500",
                                              color: "white",
                                            }}
                                            _focus={{ boxShadow: "outline" }}
                                            as={Button}
                                          >
                                            <HiDotsHorizontal />
                                          </MenuButton>
                                          <MenuList>
                                            <MenuItem
                                              onClick={() => editar(record.id)}
                                            >
                                              <EditIcon mr={2} />
                                              Editar
                                            </MenuItem>
                                            <MenuDivider />
                                            <MenuItem
                                              onClick={() =>
                                                eliminar(record.id)
                                              }
                                            >
                                              <DeleteIcon mr={2} />
                                              Eliminar
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      )}
                                    />
                                  </Table>
                                </>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </Center>
                          {/* Contactos de la empresa */}
                        </Box>
                      </Box>
                      {/* Tabla para movil*/}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Blur>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

//REDUX
const mapStateProps = (state) => ({
  link: state.linkReducer.link,
  crm: state.crmReducer.local,
  proyectos: state.proyectosReducer.proyectos,
  proyecto: state.proyectosReducer.proyecto,
});

const HomeProyectos = connect(mapStateProps, {
  get_link,
  get_crm,
  get_proyectos,
  delete_proyecto,
  edit_proyecto,
  get_proyecto_by_id,
})(_HomeProyectos);
export default HomeProyectos;
