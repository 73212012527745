import React, { useState } from "react";
import {
  Spinner,
  Input,
  Button,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import Header from "../../Header";
import { AddIcon, DragHandleIcon } from "@chakra-ui/icons";
import useUser from "../../context/useUser";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MenuVendedor from "../Utilidades/MenuVendedor";
import UploadImageToS3WithReactS3 from "../../UploadPropuestas";
import Moment from "react-moment";
import "moment-timezone";
import { nanoid } from "nanoid";
import { BsFilePdf } from "react-icons/bs";
import { RenderIf } from "../../../hooks/renderIF";
import MenuGerente from "../../Gerente/Utilidades/MenuGerente";
const Propuestas = () => {
  //let { id } = useParams();
  //const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const [datos, setDatos] = useState([]);
  //const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI)
  const [urlCassandra] = useState(process.env.REACT_APP_URL_CASSANDRA);
  const [tokenCasandra] = useState(process.env.REACT_APP_TOKEN_CASSANDRA);
  //const { token, setToken } = useToken();
  //const [clientes, setClientes] = useState([])
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [urlPresentacion] = useState();
  const [nombrePropuesta, setNombrePropuesta] = useState("");
  async function fetchPresentaciones() {
    //console.log("refetch")
    //const urlC = `${urlCassandra}collections/propuestas${user.id.toString()}?page-size=20`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/propuestas`;
    const ventasData = await axios(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log(ventasData.response);
    const presentaciones = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return presentaciones;
  }
  const crearPresentacion = async (data) => {
    const urlC = `${urlCassandra}collections/propuestas${user.id.toString()}`;
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "X-Cassandra-Token": tokenCasandra,
      },
      body: JSON.stringify({
        nombre: nombrePropuesta,
        fechaCreacion: Date.now(),
        status: "Activo",
        url: urlPresentacion,
        provider: "google",
      }),
    });

    if (response.status === 500) {
      console.log("Segundo Intento");
      const urlC = `${urlCassandra}collections/propuestas${user.id.toString()}`;
      await fetch(urlC, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-Cassandra-Token": tokenCasandra,
        },
        body: JSON.stringify({
          nombre: nombrePropuesta,
          fechaCreacion: Date.now(),
          status: "Activo",
          url: urlPresentacion,
          provider: "google",
        }),
      });
      onClose();
    }

    onClose();
    return response;
  };
  const duplicarPresentacion = async (data) => {
    console.log(data);
    //const urlC = `${urlCassandra}collections/propuestas${user.id.toString()}`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/propuestas`;
    await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombre: data.nombrePropuestas + "_" + nanoid(),
        fechaCreacion: Date.now(),
        status: data.status,
        url: data.url,
        provider: data.provider,
      }),
    });
  };
  const eliminarPresentacion = async (id) => {
    console.log(id);
    //const urlC = `${urlCassandra}collections/propuestas${user.id.toString()}/${id}`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/eliminarPropuestas/${id}`;
    await fetch(urlC, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  };
  //    async function actualizar(id){

  //    // const urlC = `${urlCassandra}collections/propuestas${id}?page-size=20`
  //     const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/propuestas`
  //     const ventasData = await axios(urlC, {
  //         method: 'GET',
  //         headers: {
  //             'accept': 'application/json',
  //             'Content-Type': 'application/json',
  //         }
  //     })
  //     console.log("[PROPUESTAS]", ventasData.response, user.id.toString())
  //     const presentaciones =  await Object.keys(ventasData.data).map((item) => ({id: item, ...ventasData.data[item]}))
  //     return presentaciones

  //     }
  const mutation_duplicar = useMutation(duplicarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_delete = useMutation(eliminarPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useMutation(crearPresentacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("PRESENTACIONES");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { isLoading, refetch } = useQuery(
    "PRESENTACIONES",
    fetchPresentaciones,
    {
      onSuccess: (data) => {
        //console.log("data:", data.nombre)
        setDatos(data);
      },
      cacheTime: Infinity,
    },
  );
  if (isLoading) {
    return (
      <Center mt={"10%"}>
        <Spinner color={"green.700"} />
      </Center>
    );
  }

  // const handleUpload = async () => {
  //     mutation.mutate()
  // }

  const handleDelete = async (id) => {
    mutation_delete.mutate(id);
  };
  const handlerNombrePropuesta = (e) => {
    setNombrePropuesta(e);
  };
  // const handlerUrlPresentacion = (e) => {
  //     setUrlPresentacion(e)
  // }
  const duplicar = (nombrePropuestas, status, url, fechaCreacion, provider) => {
    console.log("Duplicar");
    mutation_duplicar.mutate({
      nombrePropuestas,
      status,
      url,
      fechaCreacion,
      provider,
    });
  };

  return (
    <>
      <Modal
        size={"xl"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        zIndex={"9999"}
      >
        <div>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              fontSize={"lg"}
              fontWeight={"bold"}
              fontFamily={"system-ui, sans-serif"}
            >
              Subir nueva propuesta
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {/*<Box p={'4'} borderWidth={'1px'} rounded={'lg'} bg={'gray.50'} >
                         <Box my={1} fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} color={'gray.500'} >Importa una presentación desde URL de <strong>Google Slides</strong></Box>
                        <Input onChange={(e) => handlerNombrePropuesta(e.target.value) } my={2} bg={'white'} placeholder='Nombre de tú presentación' fontSize={'sm'} fontWeight={'medium'} fontFamily={'system-ui, sans-serif'} color={'gray.500'}  />
                        <Input onChange={(e) => handlerUrlPresentacion(e.target.value) } bg={'white'} fontSize={'11pt'} fontWeight={'normal'} fontFamily={'system-ui, sans-serif'} placeholder='Pega aquí la URL de tu presentación de Google Slides'></Input>
                        <Center my={3}>
                            {nombrePropuesta && urlPresentacion ? 
                            <Button onClick={handleUpload} fontSize={'sm'} fontWeight={'medium'} color={'gray.800'} fontFamily={'system-ui, sans-serif'} bg={'orange'} color={'gray.700'} variant={'outline'} >Guardar</Button>
                            :
                            <Button disabled fontSize={'sm'} fontWeight={'medium'} color={'gray.800'} fontFamily={'system-ui, sans-serif'} bg={'gray'} color={'white'} variant={'outline'}>Guardar</Button>
                        }
                        
                        </Center>
                        </Box>
                        <Box textAlign={'center'} my={4}>O</Box> */}
              <Box p={"4"} borderWidth={"1px"} rounded={"lg"} bg={"gray.50"}>
                <Box
                  my={1}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  color={"gray.500"}
                  fontFamily={"system-ui, sans-serif"}
                >
                  Escribe un nombre para tu propuesta
                </Box>
                <Input
                  bg={"white"}
                  onChange={(e) => handlerNombrePropuesta(e.target.value)}
                  type={"text"}
                  placeholder={"Propuesta de Prueba"}
                  fontSize={"sm"}
                  fontWeight={"normal"}
                  color={"gray.800"}
                  fontFamily={"system-ui, sans-serif"}
                />
                <RenderIf isTrue={nombrePropuesta !== ""}>
                  <UploadImageToS3WithReactS3
                    nombre={nombrePropuesta}
                    cerrar={onClose}
                    query={refetch}
                  />
                </RenderIf>
              </Box>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </div>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <RenderIf isTrue={user.type !== 3}>
          <MenuVendedor />
        </RenderIf>
        <RenderIf isTrue={user.type === 3}>
          <MenuGerente />
        </RenderIf>
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"gray.100"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.50, gray.200)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"gray.200"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"gray.200"} w={"100%"} h={"8.25vh"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "21px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "6", base: "5" }}
                                  >
                                    Biblioteca de Propuestas
                                  </Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"}>
                                  <Box
                                    fontSize={"17px"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    fontFamily={"system-ui, sans-serif"}
                                  ></Box>
                                </Box>
                                <Box bg={"gray.200"} w={"100%"} h={"4.25vh"}>
                                  <Box
                                    fontSize={"sm"}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    fontFamily={"system-ui, sans-serif"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    Aquí podras subir y ver las propuestas de tu
                                    compañia
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"gray.200"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"gray.200"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  <Tooltip
                                    label="Subir nueva propuesta"
                                    aria-label="A tooltip"
                                    placement="right"
                                    borderRadius={"sm"}
                                  >
                                    <Button
                                      colorScheme={"orange"}
                                      onClick={onOpen}
                                    >
                                      <AddIcon />
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                                <Box
                                  bg={"grya.100"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                ></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bgGradient="linear(to-r, gray.200, gray.50)"
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "90%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      {datos ? (
                        <Box bg={"white"} w={"100%"} mt={5}>
                          <Center>
                            <Box
                              borderWidth={1}
                              borderRadius={"12px"}
                              zIndex={1}
                              py={3}
                            >
                              <Table
                                variant="simple"
                                size={"lg"}
                                fontFamily={"system-ui, sans-serif"}
                                p={10}
                              >
                                <Thead>
                                  <Tr>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    >
                                      Nombre de la propuesta
                                    </Th>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    >
                                      Status
                                    </Th>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    >
                                      Fecha de Creación
                                    </Th>
                                    <Th
                                      fontSize={"sm"}
                                      fontWeight={"medium"}
                                      fontFamily={"system-ui, sans-serif"}
                                    ></Th>
                                  </Tr>
                                </Thead>
                                {datos
                                  .sort(
                                    (a, b) => b.fechaCreacion - a.fechaCreacion,
                                  )
                                  .map((p) => {
                                    // const url = p.url
                                    return (
                                      <Tbody size={"sm"}>
                                        <Tr key={p.id}>
                                          <Td
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                            color={"gray.700"}
                                          >
                                            {p.provider === "s3" ? (
                                              <Link
                                                href={`preview?url=${p.url}`}
                                              >
                                                <Flex>
                                                  <Box
                                                    color={"red.500"}
                                                    fontSize={"21px"}
                                                    fontWeight={"medium"}
                                                    mr={"3"}
                                                  >
                                                    <BsFilePdf />
                                                  </Box>
                                                  {p.nombre}
                                                </Flex>
                                              </Link>
                                            ) : (
                                              <Link
                                                href={p.url}
                                                target={"_blank"}
                                              >
                                                <Flex>
                                                  <Box
                                                    fontSize={"21px"}
                                                    fontWeight={"medium"}
                                                    color={"gray.700"}
                                                    mr={"3"}
                                                  >
                                                    <BsFilePdf />
                                                  </Box>
                                                  {p.nombre}
                                                </Flex>
                                              </Link>
                                            )}
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Tag colorScheme={"green"}>
                                              {p.status}
                                            </Tag>
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Moment
                                              tz="America/Mexico_City"
                                              locale="ES"
                                              format="DD/MM/yyyy hh:mm:ss"
                                            >
                                              {p.fechaCreacion}
                                            </Moment>
                                          </Td>
                                          <Td
                                            fontSize={"15px"}
                                            color={"gray.600"}
                                            fontWeight={"normal"}
                                          >
                                            <Menu>
                                              <MenuButton
                                                as={Button}
                                                rightIcon={<DragHandleIcon />}
                                              >
                                                Acciones
                                              </MenuButton>
                                              <MenuList>
                                                <MenuItem
                                                  onClick={() =>
                                                    duplicar(
                                                      p.nombre,
                                                      p.status,
                                                      p.url,
                                                      p.fechaCreacion,
                                                      p.provider,
                                                    )
                                                  }
                                                >
                                                  Duplicar
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={() =>
                                                    handleDelete(p.id)
                                                  }
                                                >
                                                  Eliminar
                                                </MenuItem>
                                              </MenuList>
                                            </Menu>
                                          </Td>
                                        </Tr>
                                      </Tbody>
                                    );
                                  })}
                              </Table>
                            </Box>
                          </Center>
                        </Box>
                      ) : (
                        "Aún no cuentas con propuestas"
                      )}
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Propuestas;
