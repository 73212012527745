import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Input,
  useToast,
  Button,
  Text,
  Box,
  Center,
  useDisclosure,
  Flex,
  Link,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Avatar,
  Divider,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Skeleton,
  Tag,
} from "@chakra-ui/react";
import axios from "axios";
import MenuAdmin from "../Utilidades/MenuAdmin";
import Header from "../../Header";
import { useParams } from "react-router-dom";
import { HiDotsHorizontal } from "react-icons/hi";
import useUser from "../../context/useUser";
import useToken from "../../useToken";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { AddIcon } from "@chakra-ui/icons";
import "moment-timezone";
import { MdDeleteForever } from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { nanoid } from "nanoid";
import * as AWS from "aws-sdk";
import { uploadFile } from "react-s3";
import { BiSlideshow } from "react-icons/bi";
AWS.config.update({
  region: "us-east-1",
  secretAccessKey: "3pmOlGDhuBJHBGc93C8MNsvuR1oxmg0n80JbSaxi",
  accessKeyId: "AKIARVGY33F62XNKJJK3",
});
//AWS.config.update({ region: 'us-east-1', secretAccessKey: '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', accessKeyId: 'AKIAWLUS3YRU4LTKLQHG' });
const S3_BUCKET = "nctech-profile";
const REGION = "us-east-1";
const ACCESS_KEY = "AKIARVGY33F62XNKJJK3";
const SECRET_ACCESS_KEY = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};
const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};
const gray = {
  50: "#EDF2F7",
  100: "#EDF2F7",
  200: "#E2E8F0",
  300: "#CBD5E0",
  400: "#A0AEC0",
  500: "#718096",
  600: "#4A5568",
  700: "#2D3748",
  800: "#1A202C",
  900: "#171923",
};
const orange = {
  50: "#FFFAF0",
  100: "#FEEBC8",
  200: "#FBD38D",
  300: "#F6AD55",
  400: "#ED8936",
  500: "#DD6B20",
  600: "#C05621",
  700: "#9C4221",
  800: "#7B341E",
  900: "#652B19",
};

const Tab = styled(TabUnstyled)`
  font-family: system-ui, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${gray[400]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${orange[200]};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${gray[50]};
    color: ${orange[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: system-ui, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  background-color: ${gray[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const Catalogos = () => {
  const lineasIDRef = React.useRef();
  let { id } = useParams();
  const [cliente, setCliente] = useState();
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    isOpen: isLineaModalOpen,
    onOpen: onOpenContactoModal,
    onClose: onCloseLineaModal,
  } = useDisclosure();
  const {
    isOpen: isCategoriaModalOpen,
    onOpen: onOpenCategoriaModal,
    onClose: onCloseCategoriaModal,
  } = useDisclosure();
  const {
    isOpen: isOrganigramaModalOpen,
    onOpen: onOpenOrganigramaModal,
    onClose: onCloseOrganigramaModal,
  } = useDisclosure();
  const {
    isOpen: isTemplateModalOpen,
    onOpen: onOpenTemplateModal,
    onClose: onCloseTemplateModal,
  } = useDisclosure();
  const {
    isOpen: isTipoAplicacionModalOpen,
    onOpen: onOpenTipoAplicacionModal,
    onClose: onCloseTipoAplicacionModal,
  } = useDisclosure();
  const {
    isOpen: isTipoAplicacionModalOpenEdit,
    onOpen: onOpenTipoAplicacionModalEdit,
    onClose: onCloseTipoAplicacionModalEdit,
  } = useDisclosure();
  const [datos, setDatos] = useState([]);
  const [urlStrapi, setUrlStrapi] = useState(process.env.REACT_APP_URL_STRAPI);
  const [urlCassandra, setUrlCassandra] = useState(
    process.env.REACT_APP_URL_CASSANDRA,
  );
  const [tokenCasandra, setTokenCassandra] = useState(
    process.env.REACT_APP_TOKEN_CASSANDRA,
  );
  const { token, setToken } = useToken();
  const [tokenShare, setTokenShare] = useState("");
  const [clientes, setClientes] = useState([]);
  const { user, setUser } = useUser();

  const [categoria, setCategoria] = useState("");
  const [nombreLinea, setNombreLinea] = useState("");
  const [categorias, setCategorias] = useState("");
  const [nombre, setNombre] = useState("");
  const [puesto, setPuesto] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsApp] = useState("");
  const [foto, setFoto] = useState("");
  const [lineaId, setLineaId] = useState("");
  const [nombreTemplate, setNombreTemplate] = useState("");
  const [template, setTemplate] = useState("");
  const [datos_c, setDatos_c] = useState([]);
  const [datos_d, setDatos_d] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [nombreTipoAplicacion, setNombreTipoAplicacion] = useState(null);
  const docClient = new AWS.DynamoDB.DocumentClient({
    apiVersion: "2022-01-01",
  });
  const [value, setValue] = useState("");
  const [tipoAplicacionId, setTipoAplicacionId] = useState(null);
  const [tipoAplicacionNombre, setTipoAplicacionNombre] = useState(null);
  async function fetchLineas() {
    //const urlC = `${urlCassandra}collections/lineas?page-size=20`
    const urlC = "https://servicesemail-production-899b13b908d7.herokuapp.com/api/lineas";
    //const urlC = 'http://localhost:8080/api/lineas'
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        // 'X-Cassandra-Token': tokenCasandra
      },
    });
    const lineas = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return lineas;
  }
  async function fetchCategorias() {
    //const urlC = `${urlCassandra}collections/categorias?page-size=20`
    const urlC =
      "https://servicesemail-production-899b13b908d7.herokuapp.com/api/categorias";
    //const urlC = 'http://localhost:8080/api/categorias'
    const ventasData = await axios.get(urlC, {
      method: "GET",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
    });
    const categorias = await Object.keys(ventasData.data).map((item) => ({
      id: item,
      ...ventasData.data[item],
    }));
    return categorias;
  }
  async function fetchTipoAplicacion() {
    //const urlC = `${urlCassandra}collections/lineas?page-size=20`
    try {
      //const urlC = 'http://localhost:8080/api/getTipoAplicacion'
      const urlC =
        "https://servicesemail-production-899b13b908d7.herokuapp.com/api/getTipoAplicacion";
      const ventasData = await axios.get(urlC, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          // 'X-Cassandra-Token': tokenCasandra
        },
      });
      const tipoAplicacion = await Object.keys(ventasData.data).map((item) => ({
        id: item,
        ...ventasData.data[item],
      }));

      return tipoAplicacion;
    } catch (error) {
      console.log(
        "No se pudo conectar al servicio de Tipo de Aplicación, consulta a tu administrador.",
      );
    }
  }
  useEffect(() => {
    setCliente(id);
    console.log(id);
  }, []);

  const eliminarLinea = async (lineaId) => {
    console.log("SINID:", id);
    //const urlC = `${urlCassandra}collections/lineas/${lineaId}`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/eliminarLineas/${lineaId}`;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        // 'X-Cassandra-Token': tokenCasandra
      },
    });
    console.log(response);

    return response;
  };
  const eliminarTipoAplicacion = async (lineaId) => {
    console.log("SINID:", id);
    //const urlC = `${urlCassandra}collections/lineas/${lineaId}`
    //const urlC = `http://localhost:8080/api/deleteTipoAplicacion/${lineaId}`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/deleteTipoAplicacion/${lineaId}`;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        // 'X-Cassandra-Token': tokenCasandra
      },
    });

    return response;
  };
  const editarTipoAplicacion = async () => {
    //console.log(nombreTipoAplicacion, tipoAplicacionId)
    try {
      const url = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/updateTipoAplicacion/${tipoAplicacionId}`;
      //const url = `${urlCassandra}collections/attachmens${user.id.toString()}/${id}`;
      //const url = `http://localhost:8080/api/updateTipoAplicacion/${tipoAplicacionId}`
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombre: nombreTipoAplicacion,
        }),
      });
    } catch (error) {
      console.error(
        "Error al actualizar el valor de download en la base de datos:",
        error,
      );
    }
    query_tipoAplicacion.refetch();
    setNombreTipoAplicacion(null);
    toast({
      title: "Success",
      description: "Archivo actualizado correctamente",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };
  const eliminarCategorias = async (id) => {
    console.log("SINID:", id);
    //const urlC = `${urlCassandra}collections/categorias/${id}`
    const urlC = `https://servicesemail-production-899b13b908d7.herokuapp.com/api/eliminarCategorias/${id}`;
    const response = await fetch(urlC, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
    });
    console.log(response);

    return response;
  };
  const deleteContact = async (ids) => {
    var params = {
      TableName: "Contactos",
      Key: {
        id: ids,
      },
    };

    docClient.delete(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        refetechContact();
      }
    });
  };
  const deleteTemplates = async (ids) => {
    var params = {
      TableName: "Templates",
      Key: {
        id: ids,
      },
    };

    docClient.delete(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        refetchTemplate();
      }
    });
  };
  const crearLineas = async (t) => {
    //console.log("SINID:" , id)
    //const urlC = `${urlCassandra}collections/lineas`
    const urlC = "https://servicesemail-production-899b13b908d7.herokuapp.com/api/lineas";
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
      body: JSON.stringify({
        nombre: nombreLinea,
        fechaCreacion: Date.now(),
      }),
    });
    return response;
  };
  const crearTipoAplicacion = async (t) => {
    //console.log("SINID:" , id)
    //const urlC = `${urlCassandra}collections/lineas`
    //const urlC = 'http://localhost:8080/api/createTipoAplicacion'
    const urlC =
      "https://servicesemail-production-899b13b908d7.herokuapp.com/api/createTipoAplicacion";
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
      body: JSON.stringify({
        nombre: nombreTipoAplicacion,
        fechaCreacion: Date.now(),
      }),
    });

    if (response.status === 201) {
      toast({
        title: "Success",
        description: "Se creo el tipo de Aplicación correctamente.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
    if (response.status === 404) {
      mutation_create_tipoAplicacion.isError(
        "No se encontro activo el servicio de tipo de aplicación, consulta a tu administrador",
      );
    }
  };
  const crearCategorias = async (t) => {
    console.log("SINID:", lineaId);
    //const urlC = `${urlCassandra}collections/categorias`
    const urlC =
      "https://servicesemail-production-899b13b908d7.herokuapp.com/api/categorias";
    const response = await fetch(urlC, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        //'X-Cassandra-Token': tokenCasandra
      },
      body: JSON.stringify({
        linea: lineaId,
        categoria: categorias,
        fechaCreacion: Date.now(),
      }),
    });
    return response;
  };
  const fetchContactos = () => {
    //console.log("proyecto1:", proyecto)
    var params = {
      TableName: "Contactos",
      //  FilterExpression: "#nom != null",
      //  ExpressionAttributeNames: {
      //      "#nom": "nombre",
      //  },
      //  ExpressionAttributeValues: {
      //      ":name": id
      //  }
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log("Query succeeded.");
        setDatos_c(data.Items);
        // data.Items.forEach(function(item) {
        //console.log(data.Items);

        // });
      }
    });
  };
  const fetchTemplates = () => {
    //console.log("proyecto1:", proyecto)
    var params = {
      TableName: "Templates",
      //  FilterExpression: "#nom != null",
      //  ExpressionAttributeNames: {
      //      "#nom": "nombre",
      //  },
      //  ExpressionAttributeValues: {
      //      ":name": id
      //  }
    };
    docClient.scan(params, function (err, data) {
      if (err) {
        console.error("Unable to query. Error:", JSON.stringify(err, null, 2));
      } else {
        console.log("Query succeeded.");
        setDatos_d(data.Items);
        // data.Items.forEach(function(item) {
        //console.log(data.Items);

        // });
      }
    });
  };
  const putData = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };

    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        console.log("Success", data);
        //refetch_a()
      }
    });
  };
  const crearTemplate = async () => {
    const templatesData = {
      id: nanoid(),
      nombre: nombreTemplate,
      template: value,
    };

    await putData_b("Templates", templatesData);
    onCloseTemplateModal();
    setValue("");
    refetchTemplate();
  };
  const putData_b = (tableName, data) => {
    var params = {
      TableName: tableName,
      Item: data,
    };

    docClient.put(params, function (err, data) {
      if (err) {
        console.log("Error", err);
      } else {
        console.log("Success", data);
        //refetch_a()
      }
    });
  };
  const crearContacto = async () => {
    const constactosData = {
      id: nanoid(),
      nombre: nombre,
      puesto: puesto,
      telefono: telefono,
      email: email,
      whatsapp: whatsapp,
      photo: fileName,
    };

    await putData("Contactos", constactosData);
    setFileName("");
    onCloseOrganigramaModal();
  };
  const mutation_b = useMutation(crearLineas, {
    onSuccess: () => {
      queryClient.invalidateQueries("LINEAS");
      onCloseLineaModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_c = useMutation(crearCategorias, {
    onSuccess: () => {
      queryClient.invalidateQueries("CATEGORIAS");
      onCloseCategoriaModal();
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_d = useMutation(eliminarLinea, {
    onSuccess: () => {
      queryClient.invalidateQueries("LINEAS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_e = useMutation(eliminarCategorias, {
    onSuccess: () => {
      queryClient.invalidateQueries("CATEGORIAS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_f = useMutation(crearContacto, {
    onSuccess: () => {
      queryClient.invalidateQueries("CONTACTOS");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_g = useMutation(crearTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries("TEMPLATES");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_create_tipoAplicacion = useMutation(crearTipoAplicacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("TIPOAPLICACION");
      onCloseTipoAplicacionModal();
    },
    onError: (error, variables, context) => {
      toast({
        title: "Error",
        description: `Hubo un problema al crear el tipo de aplicación, intenta nuevamente o contacta al administrador`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const mutation_delete_tipoAplicacion = useMutation(eliminarTipoAplicacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("TIPOAPLICACION");
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const mutation_update_tipoAplicacion = useMutation(editarTipoAplicacion, {
    onSuccess: () => {
      queryClient.invalidateQueries("TIPOAPLICACION");
      onCloseTipoAplicacionModalEdit();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  function handleOnClickCreateTipoAplicacion(t) {
    mutation_create_tipoAplicacion.mutate(t);
  }
  function handleOnClickUpdateTipoAplicacion(t) {
    mutation_update_tipoAplicacion.mutate(t);
  }
  function deleteTipoAplicacion(id) {
    mutation_delete_tipoAplicacion.mutate(id);
  }
  function handleonclickLinea(t) {
    mutation_b.mutate(t);
  }

  function deleteLinea(id) {
    mutation_d.mutate(id);
  }
  function deleteCategorias(id) {
    mutation_e.mutate(id);
  }
  function openModal(id) {
    console.log(id);
    setLineaId(id);
    onOpenCategoriaModal();
    //mutation_c.mutate(id)
  }
  function openModalTipoAplicacionEdit(id, nombre) {
    setTipoAplicacionId(id);
    setTipoAplicacionNombre(nombre);
    onOpenTipoAplicacionModalEdit();
    //mutation_c.mutate(id)
  }
  function addTemplate(t) {
    mutation_g.mutate(t);
  }
  function addCategoria(t) {
    mutation_c.mutate(t);
  }
  function addContacto() {
    mutation_f.mutate();
  }
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    handleUpload(e.target.files[0]);
  };
  const handleUpload = async (file) => {
    uploadFile(file, config)
      .then((data) => setFileName(data.location))
      .catch((err) => console.error(err));
  };

  const query_lineas = useQuery("LINEAS", fetchLineas);
  const query_tipoAplicacion = useQuery("TIPOAPLICACION", fetchTipoAplicacion);
  const { refetch: refetchTemplate } = useQuery("TEMPLATES", fetchTemplates);
  const { refetch: refetechContact } = useQuery("CONTACTOS", fetchContactos);
  const result_lineas = query_lineas.data;
  const result_tipoAplicacion = query_tipoAplicacion.data;
  const query_categorias = useQuery("CATEGORIAS", fetchCategorias);
  const result_categorias = query_categorias.data;

  const addCliente = () => {
    window.location.href = "/vendedor/clientes/new";
  };

  return (
    <>
      {/* ModalLinea */}
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isLineaModalOpen}
        onClose={onCloseLineaModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={"font"}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Lineas de Negocios
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              className={"font"}
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={"Roboto"}
              my={2}
            >
              Nombre de la linea de negocios
            </Text>
            <Input
              onChange={(e) => setNombreLinea(e.target.value)}
              mt={"1"}
              required={true}
              placeholder="Escribe aquí"
              className={"font"}
              fontSize={"11pt"}
              fontFamily={"Roboto"}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleonclickLinea}
              colorScheme="orange"
              variant={"solid"}
              className={"font"}
              mr={3}
            >
              Crear
            </Button>
            <Button onClick={onCloseLineaModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isCategoriaModalOpen}
        onClose={onCloseCategoriaModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={"font"}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Agrega nueva nota
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              className={"font"}
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={"Roboto"}
              my={2}
            >
              Nombre de la categoria
            </Text>
            <Input
              p={5}
              onChange={(e) => setCategorias(e.target.value)}
              mt={"1"}
              required={true}
              placeholder="Escribe aquí..."
              className={"font"}
              fontSize={"11pt"}
              fontFamily={"Roboto"}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => addCategoria()}
              colorScheme="orange"
              variant={"solid"}
              className={"font"}
              mr={3}
            >
              Agregar Categoría
            </Button>
            <Button onClick={onCloseCategoriaModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isOrganigramaModalOpen}
        onClose={onCloseOrganigramaModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={"font"}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Agregar nuevo contacto
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"Roboto"}
                my={2}
              >
                Nombre completo
              </Text>
              <Input
                p={5}
                onChange={(e) => setNombre(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe aquí..."
                className={"font"}
                fontSize={"11pt"}
                fontFamily={"Roboto"}
                fontWeight={"normal"}
              />
            </Box>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"Roboto"}
                my={2}
              >
                Puesto
              </Text>
              <Input
                p={5}
                onChange={(e) => setPuesto(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe aquí..."
                className={"font"}
                fontSize={"11pt"}
                fontFamily={"Roboto"}
                fontWeight={"normal"}
              />
            </Box>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"Roboto"}
                my={2}
              >
                Telefono
              </Text>
              <Input
                p={5}
                onChange={(e) => setTelefono(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe aquí..."
                className={"font"}
                fontSize={"11pt"}
                fontFamily={"Roboto"}
                fontWeight={"normal"}
              />
            </Box>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"Roboto"}
                my={2}
              >
                Correo electrónico
              </Text>
              <Input
                p={5}
                onChange={(e) => setEmail(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe aquí..."
                className={"font"}
                fontSize={"11pt"}
                fontFamily={"Roboto"}
                fontWeight={"normal"}
              />
            </Box>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"Roboto"}
                my={2}
              >
                WhatsApp
              </Text>
              <Input
                p={5}
                onChange={(e) => setWhatsApp(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe aquí..."
                className={"font"}
                fontSize={"11pt"}
                fontFamily={"Roboto"}
                fontWeight={"normal"}
              />
            </Box>
            <Box>
              <label onChange={handleFileInput} htmlFor="file">
                <input name="" type="file" id="file" hidden />
                <Flex my={6} cursor={"pointer"}>
                  <Box alignSelf={"center"}>
                    <BiSlideshow size={"20px"} color={"gray.500"} />
                  </Box>
                  <Box
                    alignContent={"center"}
                    ml={2}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.500"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    Clic aquí para seleccionar tu archivo
                  </Box>
                  <Box
                    alignContent={"center"}
                    ml={2}
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    color={"gray.800"}
                    fontFamily={"system-ui, sans-serif"}
                  >
                    {fileName}
                  </Box>
                </Flex>
              </label>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => addContacto()}
              colorScheme="orange"
              variant={"solid"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={"system-ui, sans-serif"}
              mr={3}
            >
              Crear contacto
            </Button>
            <Button onClick={onCloseOrganigramaModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isTemplateModalOpen}
        onClose={onCloseTemplateModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={"font"}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Agregar nuevo template
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"system-ui, sans-serif"}
                my={2}
              >
                Agrega un nombre a tu template
              </Text>
              <Input
                p={5}
                onChange={(e) => setNombreTemplate(e.target.value)}
                mt={"1"}
                required={true}
                placeholder="Escribe aquí..."
                className={"font"}
                fontSize={"11pt"}
                fontFamily={"system-ui, sans-serif"}
                fontWeight={"normal"}
              />
            </Box>
            <Box>
              <Text
                className={"font"}
                fontSize={"11pt"}
                fontWeight={"normal"}
                fontFamily={"system-ui, sans-serif"}
                my={2}
              >
                Escribe el template
              </Text>
              <ReactQuill theme="snow" value={value} onChange={setValue} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => addTemplate()}
              colorScheme="orange"
              variant={"solid"}
              fontSize={"sm"}
              fontWeight={"medium"}
              fontFamily={"system-ui, sans-serif"}
              mr={3}
            >
              Crear template
            </Button>
            <Button onClick={onCloseTemplateModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isTipoAplicacionModalOpen}
        onClose={onCloseTipoAplicacionModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={"font"}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Tipos de Aplicación
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              className={"font"}
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={"Roboto"}
              my={2}
            >
              Nombre del tipo de aplicación
            </Text>
            <Input
              onChange={(e) => setNombreTipoAplicacion(e.target.value)}
              mt={"1"}
              required={true}
              placeholder="Escribe aquí"
              className={"font"}
              fontSize={"11pt"}
              fontFamily={"Roboto"}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            {nombreTipoAplicacion ? (
              <Button
                onClick={handleOnClickCreateTipoAplicacion}
                colorScheme="orange"
                variant={"solid"}
                className={"font"}
                mr={3}
              >
                Crear
              </Button>
            ) : (
              <Button
                disabled
                colorScheme="orange"
                variant={"solid"}
                className={"font"}
                mr={3}
              >
                Crear
              </Button>
            )}
            <Button onClick={onCloseTipoAplicacionModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size={"lg"}
        closeOnOverlayClick={false}
        isOpen={isTipoAplicacionModalOpenEdit}
        onClose={onCloseTipoAplicacionModalEdit}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className={"font"}
            fontSize={"15pt"}
            fontWeight={"medium"}
          >
            Editar Tipo de Aplicación
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text
              className={"font"}
              fontSize={"11pt"}
              fontWeight={"normal"}
              fontFamily={"Roboto"}
              my={2}
            >
              Nombre del tipo de aplicación
            </Text>
            <Input
              onChange={(e) => setNombreTipoAplicacion(e.target.value)}
              mt={"1"}
              required={true}
              placeholder={tipoAplicacionNombre}
              className={"font"}
              fontSize={"11pt"}
              fontFamily={"Roboto"}
              fontWeight={"normal"}
            />
          </ModalBody>
          <ModalFooter>
            {nombreTipoAplicacion ? (
              <Button
                onClick={handleOnClickUpdateTipoAplicacion}
                colorScheme="orange"
                variant={"solid"}
                className={"font"}
                mr={3}
              >
                Actualizar
              </Button>
            ) : (
              <Button
                disabled
                colorScheme="orange"
                variant={"solid"}
                className={"font"}
                mr={3}
              >
                Actualizar
              </Button>
            )}

            <Button onClick={onCloseTipoAplicacionModalEdit}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex direction={{ base: "column", lg: "row" }} bg={"pink"} h={"100vh"}>
        <MenuAdmin />
        <Box
          bg={"yellow.500"}
          w={{ base: "100%", lg: "95%" }}
          h={{ base: "100vh", lg: "100vh" }}
          ml={{ lg: "5%", base: 0 }}
        >
          <Flex direction={"column"}>
            <Box
              display={{ base: "none", lg: "flex" }}
              bg={"gray.900"}
              h={{ base: "0", lg: "10vh" }}
              w={{ base: 0, lg: "100%" }}
              position={"fixed"}
              zIndex={"9999"}
            >
              <Header />
            </Box>

            <Box bg={"gray.200"} h={{ lg: "90vh", base: "90vh" }} mt={"10vh"}>
              <Flex direction={"column"}>
                <Box bg={"white"} h={{ base: "25vh", lg: "25vh" }}>
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"pink.400"}
                      w={{ base: "100%", lg: "60%" }}
                      h={{ base: "25vh", lg: "25vh" }}
                    >
                      <Flex direction={"column"}>
                        <Box bg={"gray.300"} h={"12.5vh"}>
                          <Flex direction={"row"}>
                            <Box bg={"gray.500"} w={"70%"} h={"12.5vh"}>
                              <Flex direction={"column"}>
                                <Box bg={"white"} w={"100%"} h={"auto"}>
                                  <Box
                                    alignSelf={"end"}
                                    fontSize={{
                                      lg: "17px",
                                      base: "23px",
                                      "2xl": "33px",
                                    }}
                                    fontWeight={"semibold"}
                                    color={"gray.700"}
                                    fontFamily={"Roboto"}
                                    ml={{ lg: "0", base: "5" }}
                                    mt={{ lg: "8", base: "5" }}
                                  >
                                    ¡Hola! {user.username}{" "}
                                  </Box>
                                </Box>
                                <Box bg={"white"} w={"100%"} h={"auto"}>
                                  <Box
                                    fontSize={{
                                      lg: "13px",
                                      base: "13px",
                                      "2xl": "13px",
                                    }}
                                    fontWeight={"medium"}
                                    color={"gray.500"}
                                    ml={{ lg: "0", base: "5" }}
                                    mb={{ lg: "10", base: "0" }}
                                  >
                                    <Link href="#"> Catálogos </Link>
                                  </Box>
                                </Box>
                              </Flex>
                            </Box>
                            <Box bg={"white"} w={"30%"} h={"12.5vh"}>
                              <Flex direction={"column"} justifyContent={"end"}>
                                <Box
                                  bg={"white"}
                                  w={"100%"}
                                  h={"4.16vh"}
                                  align={"end"}
                                  mt={8}
                                  pr={{ base: 10, lg: 0 }}
                                >
                                  {/* <Tooltip label="Crear nuevo proyecto" aria-label='A tooltip' placement='right' borderRadius={'sm'}>
                                                                    
                                                                    <Menu>
                                                                                <MenuButton
                                                                                    px={4}
                                                                                    py={2}
                                                                                    transition='all 0.2s'
                                                                                    borderRadius='md'
                                                                                    borderWidth='1px'
                                                                                    _hover={{ bg: 'gray.400', color: 'white' }}
                                                                                    _expanded={{ bg: 'gray.500', color: 'white' }}
                                                                                    
                                                                                    _focus={{ boxShadow: 'outline' }}
                                                                                    as={Button}

                                                                                >
                                                                                    <HiDotsHorizontal />
                                                                                </MenuButton>
                                                                                <MenuList>
                                                                                    <MenuItem>Nuevo</MenuItem>
                                                                                    <MenuDivider />
                                                                                    <MenuItem>Eliminar</MenuItem>
                                                                                </MenuList>
                                                                            </Menu>
                                                                </Tooltip> */}
                                </Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                                <Box bg={"white"} w={"100%"} h={"4.16vh"}></Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Box>
                        <Box bg={"white"} h={"12.5vh"}>
                          <Center></Center>
                        </Box>
                        <Divider />
                      </Flex>
                    </Box>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "20%" }}
                      h={{ base: "0", lg: "25vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
                <Box
                  bg={"white"}
                  h={{ base: "65vh", lg: "65vh" }}
                  pl={{ base: 0, lg: 0 }}
                  pr={{ base: 0, lg: 0 }}
                >
                  <Flex direction={{ base: "column", lg: "row" }}>
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 1 */}
                    <Box
                      bg={"white"}
                      w={{ base: "0", lg: "100%" }}
                      h={{ base: "0", lg: "65vh" }}
                    >
                      <Flex justify={"center"} w={"100%"} my={2}>
                        <TabsUnstyled defaultValue={0} my={2}>
                          <TabsList>
                            <Tab w={"100%"}>Lineas de Negocio</Tab>
                            <Tab>Organigrama</Tab>
                            <Tab>Templates</Tab>
                            <Tab>Tipos de Aplicación</Tab>
                          </TabsList>
                          <TabPanel value={0}>
                            <Flex direction={"column"}>
                              <Box>
                                <Box
                                  my={5}
                                  fontSize={"md"}
                                  fontWeight={"medium"}
                                  fontFamily={"system-ui, sans-serif"}
                                >
                                  Agrega y Administra tus CATÁLOGOS
                                </Box>
                              </Box>
                              <Flex
                                direction={"column"}
                                bg={"gray.50"}
                                my={3}
                                py={5}
                                px={5}
                                w={"100%"}
                                mx={5}
                                rounded={"lg"}
                              >
                                <Box>
                                  <Flex justify={"space-between"}>
                                    <Text
                                      alignSelf={"center"}
                                      fontFamily={"Roboto"}
                                      fontWeight={"medium"}
                                      fontSize={"15pt"}
                                    >
                                      Lineas de Negocio
                                    </Text>
                                    <Box justifyContent={"center"} ml={3}>
                                      <Tooltip
                                        label="Nueva linea de negocio"
                                        aria-label="A tooltip"
                                        placement="right"
                                        borderRadius={"sm"}
                                      >
                                        <Button
                                          my={2}
                                          colorScheme={"orange"}
                                          onClick={onOpenContactoModal}
                                        >
                                          <AddIcon />
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Flex>
                                  <Box
                                    fontFamily={"Roboto"}
                                    fontSize={"14px"}
                                    fontWeight={"normal"}
                                    color={"gray.500"}
                                  >
                                    Agrega las lineas de negocio que necesites{" "}
                                  </Box>
                                </Box>
                                <>
                                  {result_lineas ? (
                                    <Flex direction={"column"} my={"5"}>
                                      {result_lineas.map((linea, index) => {
                                        return (
                                          <Flex justify={"space-between"}>
                                            <Box
                                              mr={5}
                                              bg={"gray.200"}
                                              mb={1}
                                              rounded={"lg"}
                                              px={3}
                                              py={3}
                                              w={"100%"}
                                            >
                                              <Flex
                                                justify={"space-between"}
                                                direction={"row"}
                                              >
                                                <Box
                                                  fontFamily={"Roboto"}
                                                  fontSize={"15px"}
                                                  color={"gray.900"}
                                                  w={"100%"}
                                                >
                                                  <Flex direction={"column"}>
                                                    <Box
                                                      ml={"5"}
                                                      mt={"5"}
                                                      fontSize={"15px"}
                                                      fontWeight={"medium"}
                                                    >
                                                      {linea.nombre}
                                                    </Box>
                                                    <Box
                                                      ml={"5"}
                                                      mt={"5"}
                                                      fontSize={"15px"}
                                                      fontWeight={"normal"}
                                                    >
                                                      Sub Categorias
                                                    </Box>
                                                  </Flex>

                                                  <>
                                                    {result_categorias ? (
                                                      <Flex
                                                        direction={"column"}
                                                        my={2}
                                                        mx={5}
                                                        rounded={"lg"}
                                                      >
                                                        {result_categorias.map(
                                                          (categoria) => {
                                                            if (
                                                              categoria.linea ===
                                                              linea.id
                                                            ) {
                                                              return (
                                                                <>
                                                                  <Flex
                                                                    justify={
                                                                      "space-between"
                                                                    }
                                                                    my={1}
                                                                    w={"100%"}
                                                                  >
                                                                    <Box
                                                                      p={3}
                                                                      fontFamily={
                                                                        "Roboto"
                                                                      }
                                                                      fontSize={
                                                                        "15px"
                                                                      }
                                                                      color={
                                                                        "gray.900"
                                                                      }
                                                                      bg={
                                                                        "gray.100"
                                                                      }
                                                                      rounded={
                                                                        "sm"
                                                                      }
                                                                      w={"100%"}
                                                                    >
                                                                      <Flex
                                                                        justify={
                                                                          "space-between"
                                                                        }
                                                                      >
                                                                        <Box>
                                                                          {
                                                                            categoria.categoria
                                                                          }
                                                                        </Box>
                                                                        <Button
                                                                          onClick={() =>
                                                                            deleteCategorias(
                                                                              categoria.id,
                                                                            )
                                                                          }
                                                                          variant={
                                                                            "outline"
                                                                          }
                                                                          colorScheme={
                                                                            "orange"
                                                                          }
                                                                        >
                                                                          <MdDeleteForever />
                                                                        </Button>
                                                                      </Flex>
                                                                    </Box>
                                                                  </Flex>
                                                                </>
                                                              );
                                                            }
                                                          },
                                                        )}
                                                      </Flex>
                                                    ) : (
                                                      "No hay Categorias aún"
                                                    )}
                                                  </>
                                                </Box>
                                                <Menu>
                                                  <MenuButton
                                                    px={4}
                                                    py={2}
                                                    transition="all 0.2s"
                                                    borderRadius="md"
                                                    borderWidth="1px"
                                                    _hover={{
                                                      bg: "gray.400",
                                                      color: "white",
                                                    }}
                                                    _expanded={{
                                                      bg: "gray.500",
                                                      color: "white",
                                                    }}
                                                    _focus={{
                                                      boxShadow: "outline",
                                                    }}
                                                    as={Button}
                                                  >
                                                    <HiDotsHorizontal />
                                                  </MenuButton>
                                                  <MenuList>
                                                    <MenuItem
                                                      onClick={() =>
                                                        openModal(linea.id)
                                                      }
                                                    >
                                                      Agregar Categoría
                                                    </MenuItem>
                                                    <MenuItem
                                                      onClick={() =>
                                                        deleteLinea(linea.id)
                                                      }
                                                    >
                                                      Eliminar
                                                    </MenuItem>
                                                  </MenuList>
                                                </Menu>
                                              </Flex>
                                            </Box>
                                          </Flex>
                                        );
                                      })}
                                    </Flex>
                                  ) : (
                                    <Skeleton height="20px" />
                                  )}
                                </>
                              </Flex>
                            </Flex>
                          </TabPanel>
                          <TabPanel value={1}>
                            <Flex>
                              <Box>
                                <Box
                                  my={5}
                                  fontSize={"md"}
                                  fontWeight={"medium"}
                                  fontFamily={"system-ui, sans-serif"}
                                >
                                  Configuración de Organigrama
                                </Box>
                              </Box>
                            </Flex>
                            <Flex
                              direction={"column"}
                              bg={"gray.50"}
                              my={3}
                              py={5}
                              px={5}
                              w={"100%"}
                              mx={5}
                              rounded={"lg"}
                            >
                              <Box>
                                <Flex justify={"space-between"}>
                                  <Text
                                    alignSelf={"center"}
                                    fontFamily={"Roboto"}
                                    fontWeight={"medium"}
                                    fontSize={"15pt"}
                                  >
                                    Lista de Usuarios
                                  </Text>
                                  <Box justifyContent={"center"} ml={3}>
                                    <Tooltip
                                      label="Nuevo usuario"
                                      aria-label="A tooltip"
                                      placement="right"
                                      borderRadius={"sm"}
                                    >
                                      <Button
                                        my={2}
                                        colorScheme={"orange"}
                                        onClick={onOpenOrganigramaModal}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                </Flex>
                                <Box
                                  fontFamily={"Roboto"}
                                  fontSize={"14px"}
                                  fontWeight={"normal"}
                                  color={"gray.500"}
                                  my={5}
                                >
                                  Agrega los contactos que necesites{" "}
                                </Box>
                              </Box>
                              <>
                                <Center>
                                  <Box
                                    borderWidth={1}
                                    borderRadius={"12px"}
                                    zIndex={1}
                                    py={3}
                                  >
                                    <Table
                                      variant="simple"
                                      size={"lg"}
                                      fontFamily={"system-ui, sans-serif"}
                                      p={10}
                                    >
                                      <Thead>
                                        <Tr>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          ></Th>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            Nombre
                                          </Th>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            Puesto
                                          </Th>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            Teléfono
                                          </Th>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            Email
                                          </Th>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            WhatsApp
                                          </Th>
                                          <Th></Th>
                                        </Tr>
                                      </Thead>
                                      {datos_c.map((p, i) => {
                                        const url = p.url;
                                        return (
                                          <Tbody size={"sm"} key={i}>
                                            <Tr key={p.id}>
                                              <Td
                                                fontSize={"sm"}
                                                fontWeight={"medium"}
                                                fontFamily={
                                                  "system-ui, sans-serif"
                                                }
                                                color={"gray.700"}
                                              >
                                                <Avatar
                                                  src={p.photo}
                                                  size={"md"}
                                                />
                                              </Td>
                                              <Td
                                                fontSize={"sm"}
                                                fontWeight={"medium"}
                                                fontFamily={
                                                  "system-ui, sans-serif"
                                                }
                                                color={"gray.700"}
                                              >
                                                {p.nombre}
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                <Tag colorScheme={"green"}>
                                                  {p.puesto}
                                                </Tag>
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                {p.telefono}
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                {p.email}
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                {p.whatsapp}
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                <Button
                                                  onClick={() =>
                                                    deleteContact(p.id)
                                                  }
                                                >
                                                  Eliminar
                                                </Button>
                                              </Td>
                                            </Tr>
                                          </Tbody>
                                        );
                                      })}
                                    </Table>
                                  </Box>
                                </Center>
                              </>
                            </Flex>
                          </TabPanel>
                          <TabPanel value={2}>
                            <Flex></Flex>
                            <Flex
                              direction={"column"}
                              bg={"gray.50"}
                              my={3}
                              py={5}
                              px={5}
                              w={"100%"}
                              mx={5}
                              rounded={"lg"}
                            >
                              <Box>
                                <Flex justify={"space-between"}>
                                  <Text
                                    alignSelf={"center"}
                                    fontFamily={"Roboto"}
                                    fontWeight={"medium"}
                                    fontSize={"15pt"}
                                  >
                                    Configuración de Templates
                                  </Text>
                                  <Box justifyContent={"center"} ml={3}>
                                    <Tooltip
                                      label="Nuevo template"
                                      aria-label="A tooltip"
                                      placement="right"
                                      borderRadius={"sm"}
                                    >
                                      <Button
                                        my={2}
                                        colorScheme={"orange"}
                                        onClick={onOpenTemplateModal}
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Tooltip>
                                  </Box>
                                </Flex>
                                <Box
                                  fontFamily={"Roboto"}
                                  fontSize={"14px"}
                                  fontWeight={"normal"}
                                  color={"gray.500"}
                                  my={5}
                                >
                                  Agrega los templates que necesites{" "}
                                </Box>
                              </Box>
                              <>
                                <Center>
                                  <Box
                                    borderWidth={1}
                                    borderRadius={"12px"}
                                    zIndex={1}
                                    py={3}
                                  >
                                    <Table
                                      variant="simple"
                                      size={"lg"}
                                      fontFamily={"system-ui, sans-serif"}
                                      p={10}
                                    >
                                      <Thead>
                                        <Tr>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            Nombre del template
                                          </Th>
                                          <Th
                                            fontSize={"sm"}
                                            fontWeight={"medium"}
                                            fontFamily={"system-ui, sans-serif"}
                                          >
                                            Código de Template
                                          </Th>
                                          <Th></Th>
                                        </Tr>
                                      </Thead>
                                      {datos_d.map((p) => {
                                        const url = p.url;
                                        return (
                                          <Tbody size={"sm"}>
                                            <Tr key={p.id}>
                                              <Td
                                                fontSize={"sm"}
                                                fontWeight={"medium"}
                                                fontFamily={
                                                  "system-ui, sans-serif"
                                                }
                                                color={"gray.700"}
                                              >
                                                {p.nombre}
                                              </Td>
                                              <Td
                                                fontSize={"15px"}
                                                color={"gray.600"}
                                                fontWeight={"normal"}
                                              >
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: p.template,
                                                  }}
                                                ></div>
                                              </Td>
                                              <Td>
                                                <Button
                                                  onClick={() =>
                                                    deleteTemplates(p.id)
                                                  }
                                                >
                                                  Eliminar
                                                </Button>
                                              </Td>
                                            </Tr>
                                          </Tbody>
                                        );
                                      })}
                                    </Table>
                                  </Box>
                                </Center>
                              </>
                            </Flex>
                          </TabPanel>
                          <TabPanel value={3}>
                            <Flex direction={"column"}>
                              <Flex
                                direction={"column"}
                                bg={"gray.50"}
                                my={3}
                                py={5}
                                px={5}
                                w={"100%"}
                                mx={5}
                                rounded={"lg"}
                              >
                                <Box>
                                  <Flex justify={"space-between"}>
                                    <Text
                                      alignSelf={"center"}
                                      fontFamily={"Roboto"}
                                      fontWeight={"medium"}
                                      fontSize={"15pt"}
                                    >
                                      Tipos de Aplicación
                                    </Text>
                                    <Box justifyContent={"center"} ml={3}>
                                      <Tooltip
                                        label="Nuevo tipo de aplicación"
                                        aria-label="A tooltip"
                                        placement="right"
                                        borderRadius={"sm"}
                                      >
                                        <Button
                                          my={2}
                                          colorScheme={"orange"}
                                          onClick={onOpenTipoAplicacionModal}
                                        >
                                          <AddIcon />
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Flex>
                                  <Box
                                    fontFamily={"Roboto"}
                                    fontSize={"14px"}
                                    fontWeight={"normal"}
                                    color={"gray.500"}
                                  >
                                    Listado de tipos de aplicación para archivos
                                    públicos{" "}
                                  </Box>
                                </Box>
                                <>
                                  {result_tipoAplicacion ? (
                                    <Flex direction={"column"} my={"5"}>
                                      {result_tipoAplicacion.map(
                                        (tipo, index) => {
                                          return (
                                            <Flex justify={"space-between"}>
                                              <Box
                                                mr={5}
                                                bg={"gray.200"}
                                                mb={1}
                                                rounded={"lg"}
                                                px={3}
                                                py={3}
                                                w={"100%"}
                                              >
                                                <Flex
                                                  justify={"space-between"}
                                                  direction={"row"}
                                                >
                                                  <Box
                                                    fontFamily={"Roboto"}
                                                    fontSize={"15px"}
                                                    color={"gray.900"}
                                                    w={"100%"}
                                                  >
                                                    <Flex direction={"column"}>
                                                      <Box
                                                        ml={"5"}
                                                        mt={"2"}
                                                        fontSize={"15px"}
                                                        fontWeight={"medium"}
                                                      >
                                                        {tipo.nombre}
                                                      </Box>
                                                    </Flex>
                                                  </Box>
                                                  <Menu>
                                                    <MenuButton
                                                      px={4}
                                                      py={2}
                                                      transition="all 0.2s"
                                                      borderRadius="md"
                                                      borderWidth="1px"
                                                      _hover={{
                                                        bg: "gray.400",
                                                        color: "white",
                                                      }}
                                                      _expanded={{
                                                        bg: "gray.500",
                                                        color: "white",
                                                      }}
                                                      _focus={{
                                                        boxShadow: "outline",
                                                      }}
                                                      as={Button}
                                                    >
                                                      <HiDotsHorizontal />
                                                    </MenuButton>
                                                    <MenuList>
                                                      <MenuItem
                                                        onClick={() =>
                                                          openModalTipoAplicacionEdit(
                                                            tipo.id,
                                                            tipo.nombre,
                                                          )
                                                        }
                                                      >
                                                        Editar
                                                      </MenuItem>
                                                      <MenuItem
                                                        onClick={() =>
                                                          deleteTipoAplicacion(
                                                            tipo.id,
                                                          )
                                                        }
                                                      >
                                                        Eliminar
                                                      </MenuItem>
                                                    </MenuList>
                                                  </Menu>
                                                </Flex>
                                              </Box>
                                            </Flex>
                                          );
                                        },
                                      )}
                                    </Flex>
                                  ) : (
                                    <Skeleton height="20px" />
                                  )}
                                </>
                              </Flex>
                            </Flex>
                          </TabPanel>
                        </TabsUnstyled>
                      </Flex>
                    </Box>
                    {/* Tabla para movil*/}
                    <Box
                      display={{ base: "none", lg: "flex" }}
                      bg={"white"}
                      w={{ base: "0", lg: "5%" }}
                      h={{ base: "0", lg: "65vh" }}
                    ></Box>{" "}
                    {/* Box 3 */}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Catalogos;
